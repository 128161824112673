import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CenterBox from "../../core/CenterBox";
import { useRef, useState } from "react";
import Rate from "../../core/Rating";
import { Button, Divider, Fab, Tooltip } from "@mui/material";
import { IProudctList } from "../../models/IProductList";
import InputField from "../../core/InputField";
import ButtonUI from "../../core/Button";
import QuentityEnter from "../../core/QuentityEnter";
import Cookies from "universal-cookie";
import axios, { CancelTokenSource } from "axios";
import CircularLoader from "../../core/CircularLoader";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

interface IView {
  data: IProudctList;
}

interface IState {
  loading: boolean;
  message: string;
  error: boolean;
  open: boolean;
}

const View = ({ data }: IView) => {
  const [imgIndex, setImgIndex] = useState(0);
  console.log("Line No 31",data)
  const [note, setNote] = useState("");
  const [quantity, setQuantity] = useState(data.quantity);
  const cookies = new Cookies();
  const [state, setState] = useState<IState>({
    loading: false,
    message: "",
    error: false,
    open: false,
  });

  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const [loader, setLoader] = useState(false);
  const imgShowHandler = (index: number) => {
    setImgIndex(index);
  };

  const [source, setSource] = useState<CancelTokenSource | null>(null);

  const cartHandler = (id: string, quantity: number) => {
    setState({
      ...state,
      loading: true,
    });
    if (source) {
      source.cancel("cancel token");
    }
    const cancelToken = axios.CancelToken.source();
    setSource(cancelToken);
    const data = {
      productid: id,
      quantity: quantity,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/cart/addItemToCart`, data, {
        cancelToken: cancelToken.token,
        headers: {
          Authorization: `Bearer ${cookies.get("user-token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setState({
          ...state,
          loading: false,
          message: response.data.message,
          open: true,
          error: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          loading: false,
          message: error.response.data.message,
          open: true,
          error: true,
        });
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ background: "#fff", borderRadius: "8px" }}>
      <Grid item md={12} p={2}>
        <Grid container height={"100%"}>
          <Grid item md={5}>
            <CenterBox height="100%">
              <div>
                <CenterBox height="100%">
                  <img
                    src={
                      data?.productPicture
                        ? data?.productPicture[0].img
                        : "/icons/placeHolder.png"
                    }
                    width={"250px"}
                    style={{
                      padding: "10px",
                      height: "250px",
                    }}
                  ></img>
                </CenterBox>
                <br />
                <Box
                  sx={{
                    height: "60px",
                    width: "100%",
                  }}
                  px={2}
                  mt={1}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    {data?.productPicture ? (
                      data.productPicture.map((data, index) => {
                        return (
                          <Box onMouseOver={() => imgShowHandler(index)}>
                            <CenterBox key={index}>
                              <img
                                src={
                                  imgIndex === index
                                    ? data.img
                                    : "/icons/placeHolder.png"
                                }
                                loading="lazy"
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  width: "60px",
                                  height: "60px",
                                  boxShadow:
                                    imgIndex === index
                                      ? "rgba(0, 0, 0, 0.05) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                                      : "",
                                }}
                              />
                            </CenterBox>
                          </Box>
                        );
                      })
                    ) : (
                      <CenterBox>
                        <img
                          src={"/icons/placeHolder.png"}
                          loading="lazy"
                          height="60px"
                          width="60px"
                          style={{
                            border: "1px solid #ccc",
                            padding: "30px",
                            borderRadius: "10px",
                          }}
                        />
                      </CenterBox>
                    )}
                  </Box>
                </Box>
              </div>
            </CenterBox>
          </Grid>
          <Grid item md={7}>
            <Box sx={{ width: "100%" }}>
              <Typography component={"span"} fontSize={"1rem"}>
                <strong> {data?.name}</strong>
                <br />
                {data?.description}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Rate value={4.5} />
              </Box>
              <Box py={1}>
                <Divider />
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between" }}
                py={1.5}
              >
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    color={"#000"}
                    lineHeight={"17px"}
                    px={2}
                  >
                    UOM :
                    <Typography variant="h5" color={"#000"} lineHeight={"17px"}>
                      {data?.uom}
                    </Typography>
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography
                    variant="h6"
                    color={"#000"}
                    lineHeight={"17px"}
                    px={2}
                  >
                    IMPA CODE :
                    <Typography variant="h5" color={"#000"} lineHeight={"17px"}>
                      {data?.impaCode}
                    </Typography>
                  </Typography>
                </div>
                <Divider orientation="vertical" flexItem />
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "150px" }}>
                    <QuentityEnter
                      quantity={quantity}
                      increaseClickHandler={increaseClickHandler}
                      decreaseClickHandler={decreaseClickHandler}
                      InputHandler={InputHandler}
                    ></QuentityEnter>
                  </div>
                </div>
              </Box>
              <Box pt={1}>
                <Divider />
              </Box>
              <Box className={"center"} height={"auto"} gap={"30px"}>
                <Box
                  height={"100%"}
                  maxHeight={"100px"}
                  width={"100%"}
                  p={1}
                  border={"1px solid #ccc"}
                  sx={{
                    boxShadow:
                      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                    position: "relative",
                  }}
                  borderRadius={"10px"}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      maxHeight: "80px",
                      overflowY: "auto",
                    }}
                  >
                    Add Your Remark:
                    <br />
                    <textarea
                      rows={3}
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        resize: "none",
                      }}
                      placeholder="Remark"
                      onKeyDown={handleKeyDown}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                disabled={quantity > 0 ? false : true}
                onClick={() =>
                  cartHandler(data?._id ? data._id : "asdsad", quantity)
                }
                sx={{
                  fontSize: "10px",
                  height: "35px",
                  width: "150px",
                  float: "right",
                  mr: "12px",
                }}
                size="small"
              >
                {!state?.loading ? "Add To Cart" : <CircularLoader />}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default View;
