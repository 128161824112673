import instanceAxios from "../hooks/axiosConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export class ProductListService {
  public static getAllProducts(portId: string, category: string) {
    const data = {
      portId,
      category,
    };
    return instanceAxios().get("/getproduct", {
      params: {
        portId: data.portId,
        category: data.category,
      },
    });
  }

  public static getAllProductsByCategory(
    category: string,
    page: number,
    pageSize?: number
  ) {
    const data = {
      category,
      page,
      pageSize,
    };
    return instanceAxios().get("/getProductsByCategory", {
      params: {
        categoryId: data.category,
        page: data.page,
        pageSize: data.pageSize,
      },
    });
  }

  public static getSearchProduct(query: string, category: string) {
    return instanceAxios().get("/search", {
      params: {
        query: query,
      },
    });
  }

  public static geProductDetail(key: string, value: string) {
    return instanceAxios().get("/getProductDetail", {
      params: {
        [key]: value,
      },
    });
  }

  public static getAttributes(categoryId: string) {
    const response = {
      status: 200,
      message: [
        {
          name: "Price",
          type: "dropdown",
          typeValue: [
            {
              name: "Blue",
              _id: "647a37ed6da95e02c82e673c",
            },
            {
              name: "Orange",
              _id: "647a382b6da95e02c82e6741",
            },
            {
              name: "Orang",
              _id: "647a388386069455d8c9619a",
            },
          ],
          _id: "647a37900aedd1a211df2583",
        },
        {
          name: "Brand",
          type: "radio",
          typeValue: [
            {
              name: "MI",
              _id: "647b738cdc0770674dc2e387",
            },
            {
              name: "Apple",
              _id: "647b738cdc0770674dc2e388",
            },
          ],
          _id: "647b738cdc0770674dc2e386",
        },
      ],
      time: "2023-06-06T17:21:58.579Z",
    };
    return response;
  }
}
