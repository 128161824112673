import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  toolBar: {
    padding: "0px !important",
    height: "60px",
    position: "relative",
    background: "#00072d",
    // background: "#7796cb",
    // background: "#6f90f4",
  },
  minHeight: {
    height: "60px",
  },
  Logo: {
    padding: 5,
    border: "1.5px solid transprant",
    "&:hover": {
      border: "1.5px solid #fff",
    },
  },
  Port: {
    display: "flex",
    padding: 5,
    border: "1.5px solid transprant",
    "&:hover": {
      border: "1.5px solid #fff",
    },
  },

  AccountListHover: {
    display: "flex",
    padding: 5,
    border: "1.5px solid transprant",
    "&:hover": {
      border: "1.5px solid #fff",
    },
  },

  LanguageListHover: {
    display: "flex",
    padding: 5,
    border: "1.5px solid transprant",
    "&:hover": {
      border: "1.5px solid #fff",
    },
  },

  AccountDetails: {
    width: "500px",
  },
  Center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  flex: {
    display: "flex",
  },
  menuItems: {
    "&.MuiMenuItem-root": {
      padding: "2px 0px",
      "&:hover": {
        h6: {
          color: "#fff",
        },
        background: "none",
      },
    },
  },
  Language: {
    position: "absolute",
    background: "#fff",
    height: "auto",
    width: "200px",
    right: "18%",
    top: "100%",
    zIndex: 1,
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
});
export default useStyles;
