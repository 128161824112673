import { alpha, createTheme } from "@mui/material/styles";

export const Palette = {
  primary: "#001F3F",
  secondary: "#0c5273",
  background: "#fff",
  accent1: "#40E0D0",
  accent2: "#FF7F50",
  black: "#111",
  lWhite: "#ccc",
  lBoder: "#E7E7E7",
  hr: "#767676",
  lightBlack: "#0F1111",
};

const theme = createTheme({
  typography: {
    fontFamily: "Amazon Ember",
    fontSize: 10,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontWeightLight: 700,
    fontWeightRegular: 700,

    h1: {
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "36px",
      textTransform: "capitalize",
      color: Palette.black,
    },
    h2: {
      fontSize: "1rem",
      fontWeight: 400,
      textTransform: "capitalize",
    },
    h3: {
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: 700,
      textTransform: "uppercase",
      // color: "#0f1111",
      color: Palette.black,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 700,
      color: Palette.black,
    },
    h5: {
      fontSize: "0.8rem",
      lineHeight: "14px",
      fontWeight: 700,
      color: Palette.background,
    },
    h6: {
      fontSize: "0.75rem",
      lineHeight: "14px",
      fontWeight: 500,
      textTransform: "capitalize",
      color: Palette.lWhite,
    },
    body1: {
      fontSize: "16.07px",
      fontWeight: 400,
      color: Palette.black,
      lineHeight: "21.34px",
    },
    body2: {
      color: Palette.black,
      fontSize: "13.43px",
      fontWeight: 500,
      lineHeight: "18px",
    },
    subtitle1: {
      fontSize: "13px",
      fontWeight: 700,
      textTransform: "capitalize",
      color: "#0ff121",
    },
    subtitle2: {
      fontSize: "13px",
      lineHeight: "20px",
      fontWeight: 400,
      whiteSpace: "normal",
      wordWrap: "break-word",
      color: Palette.black,
    },
    button: {
      fontSize: "10px",
      fontWeight: 700,
      color: "#000",
      textTransform: "inherit",
    },
  },
  palette: {
    primary: {
      main: Palette.primary,
      light: Palette.accent1, // Adjust based on where you want to use accent colors
      dark: Palette.accent2,
    },
    secondary: {
      main: Palette.secondary,
      dark: "#031C3D", // Adjust this based on your needs
      light: "#fff",
    },
    background: {
      default: Palette.background,
    },

    // Add any other necessary palette configurations based on the given color palette
  },
  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 1024,
      lg: 1536,
      xl: 1920,
    },
  },
  components: {
    // MuiStepLabel: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-disabled": {
    //         opacity: 0.1,
    //       },
    //     },
    //   },
    // },

    MuiDivider: {
      styleOverrides: {
        root: {
          color: Palette.hr,
          fontSize: "11px",
          fontWeight: "400",
          "::before": {
            borderTop: `thin solid ${Palette.lBoder}`,
          },
          "::after": {
            borderTop: `thin solid ${Palette.lBoder}`,
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          "@media (min-width: 500px)": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(Palette.background, 1),
          padding: "0.0rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          background: "#fffff",
          backgroundRepeat: "no-repeat",
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#FFF", 0.02),
          backgroundImage: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "24px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: "transparent",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "#000",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
        badge: {
          background: "#E38B29",
          color: "#000",
          height: 20,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "0.125rem",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          marginBottom: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        },
        message: {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
        },
        icon: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        filled: {
          minWidth: "300px",
          backdropFilter: "blur(16px)",
        },
        filledInfo: {
          backgroundColor: alpha("#43A5FF", 1),
          color: "#fff",
        },
        filledSuccess: {
          backgroundColor: alpha("#339900", 1),
          color: "#fff",
        },
        filledError: {
          backgroundColor: alpha("#cc3300", 1),
          color: "#fff",
        },
        filledWarning: {
          backgroundColor: alpha("#FF8A1E", 1),
          color: "#fff",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "4rem",
          height: "2rem",
          padding: 0,
          marginLeft: "0.25rem",
        },
        track: {
          borderRadius: "1.5rem",
          border: `2px solid ${alpha("#FFFFFF", 0.24)}`,
          background: "transparent",
        },
        switchBase: {
          color: "white",
          "&.Mui-checked": {
            color: "white",
            right: "0.625rem",
            left: "inherit",
            "& + .MuiSwitch-track": {
              background: "#F2BC68",
              opacity: 1,
            },
          },
        },
        thumb: {
          width: "1.5rem",
          height: "1.5rem",
          position: "relative",
          top: "-0.313rem",
          left: "-0.313rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          height: "35px",
          fontSize: "13px",
          fontWeight: "400",
          lineHeight: "29px",
          textTransform: "capitalize",
          color: Palette.lightBlack,
          border: "none",
          "&:hover": {
            backgroundColor: Palette.lightBlack,

            border: "none",
          },
        },
        contained: {
          borderRadius: "8px",
          // boxShadow: "0 2px 5px 0 rgb(213 217 217 / 50%)",
          background: "#FFD814",

          // border: "1px solid #FCD200",
          color: "#000",
          fontWeight: "bold",
          // boxShadow: "inset 4px 4px 3px 0px rgba(255, 255, 255, 0.20)",
          // filter: "drop-shadow(0px 4px 4px rgba(255, 102, 0, 0.30))",

          "&:hover": {
            background: "#ffea80",
            color: "#000",
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: "#ffd8143b",
            color: "#000",
          },
        },
        text: {
          height: "33px",
          fontSize: "14px",
          color: "#000",
          fontWeight: "400",
          "&:hover": {
            background: "#f6f6f6",
            color: "#000",
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: "#001f3f",
            color: "#000",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // "& :hover": {
          //   background: Palette.accent2,
          //   // color: Palette.accent2,
          // },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          p: 0,
          m: 0,
          border: "1px solid rgba(255, 255, 255, 0)",
          cursor: "pointer",
          "&:hover": {
            borderRadius: "6px",
            "& .MuiListItemIcon-root": {
              color: "#ff9900",
            },
            "& .MuiListItemText-primary": {
              color: "#ff9900",
            },
          },
          "&.Mui-selected": {
            background: "#f5f5f5",
            borderRadius: "6px",
            "& .MuiListItemIcon-root": {
              color: "#ff9900",
            },
            "& .MuiListItemText-primary": {
              color: "#ff9900",
            },
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          marginLeft: "0px",
          marginRight: "0px",
        },
        separator: {
          marginLeft: "3px",
          marginRight: "3px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: "0px",
          paddingRight: "0px",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
          background: Palette.accent2,
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "rgba(251, 194, 82, 0.71)",
          borderRadius: "8px 8px 0px 0px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeMedium: {
          fontSize: "15px",
        },
      },
    },
  },
});

export default theme;
