import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import filterMockData from "../../../mockData/productlist/filterMockData.json";
import useQuery from "../../../hooks/useQuery";
import { useEffect, useState } from "react";
import { ProductListService } from "../../../services/ProductListService";
import { IFilter, MessageEntity } from "../../../models/IFilter";

interface IState {
  loading: boolean;
  List: MessageEntity[];
  errorMsg: string;
}

const Filter = () => {
  const query = useQuery();
  const [state, setState] = useState<IState>({
    loading: false,
    List: [] as MessageEntity[],
    errorMsg: "",
  });

  useEffect(() => {
    const response = ProductListService.getAttributes(
      query.get("impa-catalogue")!
    );
    setState({
      ...state,
      loading: false,
      List: response.message,
    });

    console.log(response.message);
  }, [query.get("port"), query.get("impa-catalogue")]);

  return (
    <Box
      pt={1.2}
      px={1.5}
      height="100%"
      width={"270px"}
      borderLeft={"1px solid #f1f1f1"}
    >
      <Box py={0}>
        {state?.List.map((data, index) => {
          return (
            <Box key={index} pb={0.8}>
              <Typography variant="h4" fontSize={"13.5px"}>
                {data.name}
              </Typography>
              <FormGroup>
                {data?.typeValue?.map((data, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={false}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              p: 0,
                            },
                            "&.Mui-checked": {
                              color: "#007185",
                            },
                          }}
                        />
                      }
                      label={data.name}
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          padding: 0,
                          margin: 0,
                        },
                        height: "25px",
                      }}
                      key={index}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default Filter;
