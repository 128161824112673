import SpaceBetween from "../../../core/SpaceBetween";
import Bread from "../../../core/BreadCrumb";
import Typography from "@mui/material/Typography";
import useCategoryHierarchyStore from "../../../store/Data/CategoryHierarchy";
const BreadCrumb = () => {
  const { categoryhierarchy } = useCategoryHierarchyStore((state) => ({
    categoryhierarchy: state.categoryhierarchy,
  }));

  return (
    // <SpaceBetween py={0.5}>
    //   <Typography variant="h3" fontWeight={"700"}>
    //     {categoryhierarchy?.message && categoryhierarchy.message[2]?.name}
    //   </Typography>
    //   <Bread
    //     mainCategory={
    //       categoryhierarchy?.message && categoryhierarchy.message[0]?.name
    //     }
    //     category={
    //       categoryhierarchy?.message && categoryhierarchy.message[1]?.name
    //     }
    //     subCategory={
    //       categoryhierarchy?.message && categoryhierarchy.message[2]?.name
    //     }
    //   />
    // </SpaceBetween>
    <>
      <Bread
        mainCategory={
          categoryhierarchy?.message && categoryhierarchy.message[0]?.name
        }
        category={
          categoryhierarchy?.message && categoryhierarchy.message[1]?.name
        }
        subCategory={
          categoryhierarchy?.message && categoryhierarchy.message[2]?.name
        }
      />
    </>
  );
};
export default BreadCrumb;
