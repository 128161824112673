import { Grid, Toolbar, Container, Box } from "@mui/material";
import useStyles from "./style";
import LeftSide from "./LeftSide";
import Middle from "./Middle";
import RightSide from "./RightSide";
import { useTheme } from "@mui/material/styles";
import AppBar from "../../../core/AppBar";
import SpaceBetween from "../../../core/SpaceBetween";

const SubHeader = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <AppBar zIndex={0}>
      <Toolbar className={`${classes.toolBar}`}>
        <Container
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            gap: "10px",
          }}
          maxWidth={false}
        >
          <Box height={"100%"}>
            <SpaceBetween height="100%">
              <Box width={"100%"} display={"flex"} height={"100%"}>
                <LeftSide />
                <Middle />
              </Box>
              <RightSide />
            </SpaceBetween>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default SubHeader;
