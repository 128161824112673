import { useEffect, useState } from "react";
import { IState } from "../../models/IState";
import { IQuotation } from "../../models/IQuotation";
import { QuotationService } from "../../services/QuotationService";
import { Box, Paper, Typography, Grid, Button, TableHead } from "@mui/material";
import Backdrop from "../../core/Backdrop";
import CircularLoader from "../../core/CircularLoader";
import Tosted from "../../core/Tosted";
import Container from "../../core/Container";
import SpaceBetween from "../../core/SpaceBetween";
import Head from "../../core/HeadLine";
import QuotationList from "./Helper/QuotationList";

const ViewQuotation = () => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [data, setData] = useState<IQuotation>();

  const getSupplierQuotion = async () => {
    try {
      const response = await QuotationService.getQuotationList();
      if (response.status === 201) {
        setState({ ...state, loader: false });
        setData(response.data);
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    setState({ ...state, loader: true });
    getSupplierQuotion();
  }, []);

  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [state.tosted]);

  const { tosted, loader, severity, message } = state;

  return (
    <>
      <Box height={"100vh"}>
        {loader ? (
          <Backdrop open={loader}>
            <CircularLoader />
          </Backdrop>
        ) : (
          <Box py={2}>
            <Container>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  position: "relative",
                  height: "100%",
                  background: "#fff",
                }}
              >
                <Grid container className="center" spacing={2}>
                  <Grid item md={8}>
                    <SpaceBetween>
                      <Head
                        title="Quotation"
                        subTitle="Your Order summary"
                      ></Head>
                    </SpaceBetween>
                    {/* <Divider /> */}

                    <Box py={2}>
                      <Box
                        sx={{
                          background: "#fff",
                          height: "500px",
                          border: "1px solid #ccc",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                          borderRadius: "8px",
                          overflowY: "auto",
                        }}
                      >
                        <Box sx={{ height: "100%" }}>
                          {data && data.message && data.message.quotation && (
                            <QuotationList data={data.message.quotation} />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={3.5}>
                    <Box
                      mt={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "400px",
                        width: "100%",
                      }}
                    >
                      <Paper
                        sx={{ width: "90%", p: 2, borderRadius: "16px" }}
                        elevation={3}
                      >
                        <Box px={2}>
                          <Typography variant="h4" lineHeight={"45px"}>
                            Request for Quotation
                          </Typography>
                          <Typography variant="h6" color={"#000"}>
                            Your order is eligible for FREE Delivery
                          </Typography>
                          <Box py={1}>
                            <Typography variant="h2" lineHeight={"35px"}>
                              Total (10 Items)
                            </Typography>
                          </Box>
                        </Box>
                        <Box textAlign={"center"} sx={{ p: 2 }}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{
                              fontSize: "13px",
                              textTransform: "capitalize",
                            }}
                          >
                            Pay Now
                          </Button>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        )}
      </Box>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default ViewQuotation;
