import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const PreviousBtnSm = (props: any) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      <div
        className={className}
        onClick={onClick}
        // style={{ left: "0px", top: "5px" }}
      >
        <ArrowBackIosNewIcon
          sx={{
            // marginTop: "90px",
            color: "rgb(246, 246, 246,50%)",
            fontSize: "40px",
            borderRadius: "10px",
            background: "#f6f6f6",
            height: "100%",
            border: "1px solid #ccc",
            transition: "1s",
            "&:hover": {
              border: "2.5px solid #448ccb",
              background: "rgb(246, 246, 246,50%)",
            },
            fill: "#000",
          }}
        />
      </div>
    </>
  );
};
export default PreviousBtnSm;
