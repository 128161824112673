import { Divider, Box } from "@mui/material";
import NotLogin from "../../../../components/NotLogin";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import useLoginStore from "../../../../store/login";
import usePortIdStore from "../../../../store/Data/portId";
import usePortDetailStore from "../../../../store/Data/portDetail";
import Cookies from "universal-cookie";

const AccountDetails = () => {
  const { token, setToken } = useLoginStore((state) => ({
    token: state.token,
    setToken: state.setToken,
  }));
  const cookies = new Cookies();
  const { portId, setPortId } = usePortIdStore((state) => ({
    portId: state.portId,
    setPortId: state.setPortId,
  }));
  const { setPortDetail } = usePortDetailStore((state) => ({
    setPortDetail: state.setPortDetail,
  }));

  const logoutHandler = () => {
    cookies.remove("user-token");
    setToken(null);
    setPortId(null);
    setPortDetail({
      _id: "",
      name: "",
      slug: "",
      state: "",
      country: {
        _id: "",
        name: "",
        slug: "",
        code: "",
      },
    });
  };

  const AccountLoginMenu = () => {
    return (
      <Grid container sx={{ width: "100%" }}>
        <Grid item sm={12}>
          {!token && (
            <>
              <NotLogin />
              <Divider />
            </>
          )}
        </Grid>
        <Grid item sm={12}>
          <Grid container px={2} py={1}>
            <Grid
              item
              xs={6}
              sx={{
                borderRight: "1px",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "rgba(0, 0, 0, 0.12)",
                borderRightWidth: "thin",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                fontSize={"14px"}
                gutterBottom
              >
                {" "}
                Your List
              </Typography>
              <MenuList sx={{ p: 0, m: 0 }}>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/"}>
                    <Typography variant="subtitle2">Your List</Typography>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/"}>
                    <Typography variant="subtitle2">Your Gift</Typography>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/"}>
                    <Typography variant="subtitle2">Your Offer</Typography>
                  </Link>
                </MenuItem>
              </MenuList>
            </Grid>
            <Grid item xs={6} px={2}>
              <Typography
                variant="body1"
                fontWeight={600}
                fontSize={"14px"}
                gutterBottom
              >
                Your Account
              </Typography>
              <MenuList sx={{ p: 0, m: 0 }}>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/your-account"}>
                    <Typography variant="subtitle2">Your Account</Typography>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/your-account/order-history"}>
                    <Typography variant="subtitle2">Your Order</Typography>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.menuItems}>
                  <Link to={"/cart"}>
                    <Typography variant="subtitle2">Your Cart</Typography>
                  </Link>
                </MenuItem>
                {token && (
                  <>
                    <Divider />
                    <MenuItem className={classes.menuItems}>
                      <Link to={"/"}>
                        <Typography variant="subtitle2">
                          Switch Account
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItems}>
                      <Link to={"#"} onClick={logoutHandler}>
                        <Typography variant="subtitle2">Logout</Typography>
                      </Link>
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const classes = useStyles();

  return <AccountLoginMenu />;
};
export default AccountDetails;
