import Page from "../../components/Page";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";

import SupplierCard from "./Helper/Quotation/supplierCard";

const Quatation = () => {
  const classes = useStyles();

  return (
    <Page title="Your Quotation" description="">
      <Box component="main" p={1.5} className={classes.flexGrow}>
        <Typography variant="h4">Your Quotation</Typography>
        <Box display={"flex"} gap={"10px"} py={1}>
          {["1", "2", "3", "4"].map((data) => {
            return <SupplierCard id={data} name="" />;
          })}
        </Box>
      </Box>
    </Page>
  );
};
export default Quatation;
