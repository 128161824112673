import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Card from "../../../sections/@Products/ProductCart";
import MBCard from "../../../sections/@Products/MBCard";

import { useEffect, useState } from "react";
import { ProductListService } from "../../../services/ProductListService";
import { IProudctList } from "../../../models/IProductList";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import View from "./View";
import useQuery from "../../../hooks/useQuery";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { TablePagination } from "@mui/material";

interface IState {
  loading: boolean;
  product: IProudctList[];
  errorMsg: string;
}
interface IProps {
  show: boolean;
}

const ProductList = ({ show }: IProps) => {
  const classes = useStyles();
  const query = useQuery();

  const [viewItem, setViewItem] = useState<string>();
  const [state, setState] = useState<IState>({
    loading: false,
    product: [] as IProudctList[],
    errorMsg: "",
  });
  const [open, setOpen] = useState(false);
  const modelHandler = () => {
    setOpen(!open);
  };

  // const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const fetchData = async () => {
    try {
      setState({ ...state, loading: true });
      if (query.get("impa-catalogue")) {
        const response = await ProductListService.getAllProductsByCategory(
          query.get("impa-catalogue")!,
          page,
          rowsPerPage
        );
        const newObject = response.data.message.data.map(
          (data: IProudctList) => ({
            ...data,
            quantity: data.quantity ? data.quantity : 0,
          })
        );
        setTotalCount(response.data.message.totalCount);
        setState({ ...state, loading: false, product: newObject });
      } else if (query.get("i") || query.get("q")) {
        const response = await ProductListService.getSearchProduct(
          query.get("i")!,
          query.get("q")!
        );

        if (response.data.data.length > 30) {
          alert("max");
        } else {
          const newObject = response.data.data.map((data: IProudctList) => ({
            ...data,
            quantity: 0,
          }));
          setState({ ...state, loading: false, product: newObject });
        }
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);

      setState({
        ...state,
        loading: false,
        errorMsg: error.response
          ? error.response.data.message
          : "An error occurred while fetching data.",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [query.get("impa-catalogue"), query.get("i"), query.get("q")]);

  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setPage(1);
    setRowsPerPage(10);
  }, [query.get("impa-catalogue")]);

  const handleClose = () => {
    setOpen(false);
  };

  const onViewHandler = (slug: string) => {
    setViewItem(slug);
    modelHandler();
  };

  return (
    <>
      <Box
        sx={{
          overflowY: "auto",

          borderRadius: "10px",
          maxHeight: window.innerHeight - 250,
          height: "100%",
          position: "relative",
          width: "100%",
        }}
        className={classes.centerBox}
      >
        {show ? (
          <>
            <Grid
              container
              sx={{
                height: "45px",
                background: "#D2DAFF",
              }}
            >
              <Grid
                item
                sm={1.5}
                sx={{ position: "relative" }}
                borderLeft={"1px solid #ccc"}
                borderRight={"1px solid #ccc"}
              ></Grid>
              <Grid
                item
                sm={1.2}
                borderRight={"1px solid #ccc"}
                className={classes.center}
              >
                <Typography
                  className={`${classes.center} ${classes.txtHeading}`}
                >
                  IMPA NO.
                </Typography>
              </Grid>

              <Grid
                item
                sm={4.3}
                borderRight={"1px solid #ccc"}
                className={classes.center}
              >
                <Typography
                  className={`${classes.center} ${classes.txtHeading}`}
                >
                  PRODUCT LIST
                </Typography>
              </Grid>

              <Grid
                item
                sm={1}
                borderRight={"1px solid #ccc"}
                className={classes.center}
              >
                <Typography
                  className={`${classes.center} ${classes.txtHeading}`}
                >
                  UOM
                </Typography>
              </Grid>
              <Grid
                item
                sm={2}
                borderRight={"1px solid #ccc"}
                className={classes.center}
              >
                <Typography
                  className={`${classes.center} ${classes.txtHeading}`}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid
                item
                sm={2}
                borderRight={"1px solid #ccc"}
                className={classes.center}
              >
                <Typography
                  className={`${classes.center} ${classes.txtHeading}`}
                >
                  ACTION
                </Typography>
              </Grid>
            </Grid>
            {state.loading ? (
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => {
                return (
                  <Grid
                    container
                    sx={{
                      background: data % 2 === 0 ? "#EEF1FF" : "#fff",
                      height: "75px",
                    }}
                  >
                    <Grid
                      item
                      sm={1.5}
                      sx={{ position: "relative" }}
                      borderLeft={"1px solid #ccc"}
                      borderRight={"1px solid #ccc"}
                      height={"100%"}
                      p={0.6}
                    >
                      <Skeleton animation="wave" height={"100%"}></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={1.2}
                      borderRight={"1px solid #ccc"}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                      p={2}
                    >
                      <Skeleton
                        animation="wave"
                        height={"16px"}
                        width={"65px"}
                      ></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={4.3}
                      borderRight={"1px solid #ccc"}
                      px={1}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={16}
                        width={"100%"}
                      ></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      borderRight={"1px solid #ccc"}
                      px={1}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"16px"}
                        width={"65px"}
                      ></Skeleton>
                    </Grid>

                    <Grid
                      item
                      sm={2}
                      borderRight={"1px solid #ccc"}
                      px={0.6}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"35px"}
                        width={"80px"}
                      ></Skeleton>
                    </Grid>

                    <Grid
                      item
                      sm={2}
                      borderRight={"1px solid #ccc"}
                      px={0.6}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"35px"}
                        width={"80px"}
                      ></Skeleton>
                    </Grid>
                  </Grid>
                );
              })
            ) : state.product.length > 0 ? (
              <>
                {state.product.map((data, index) => {
                  return (
                    <Card
                      key={index}
                      id={index}
                      data={data}
                      modelHandler={() => onViewHandler(data.slug)}
                    />
                  );
                })}
              </>
            ) : (
              <Typography variant="h4">{state.errorMsg}</Typography>
            )}
          </>
        ) : (
          <>
            {state.loading ? (
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => {
                return (
                  <Grid
                    container
                    sx={{
                      background: data % 2 === 0 ? "#EEF1FF" : "#fff",
                      height: "75px",
                    }}
                  >
                    <Grid
                      item
                      sm={1.5}
                      sx={{ position: "relative" }}
                      borderLeft={"1px solid #ccc"}
                      borderRight={"1px solid #ccc"}
                      height={"100%"}
                      p={0.6}
                    >
                      <Skeleton animation="wave" height={"100%"}></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={1.2}
                      borderRight={"1px solid #ccc"}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                      p={2}
                    >
                      <Skeleton
                        animation="wave"
                        height={"16px"}
                        width={"65px"}
                      ></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={4.3}
                      borderRight={"1px solid #ccc"}
                      px={1}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={16}
                        width={"100%"}
                      ></Skeleton>
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      borderRight={"1px solid #ccc"}
                      px={1}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"16px"}
                        width={"65px"}
                      ></Skeleton>
                    </Grid>

                    <Grid
                      item
                      sm={2}
                      borderRight={"1px solid #ccc"}
                      px={0.6}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"35px"}
                        width={"80px"}
                      ></Skeleton>
                    </Grid>

                    <Grid
                      item
                      sm={2}
                      borderRight={"1px solid #ccc"}
                      px={0.6}
                      className={classes.center}
                    >
                      <Skeleton
                        animation="wave"
                        height={"35px"}
                        width={"80px"}
                      ></Skeleton>
                    </Grid>
                  </Grid>
                );
              })
            ) : state.product.length > 0 ? (
              <>
                <Grid container>
                  {state.product.map((data, index) => {
                    return (
                      <Grid md={3}>
                        <MBCard
                          key={index}
                          id={index}
                          data={data}
                          modelHandler={() => onViewHandler(data.slug)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            ) : (
              <Typography variant="h4">{state.errorMsg}</Typography>
            )}
          </>
        )}
      </Box>

      <Stack spacing={5} className={classes.center}>
        <TablePagination
          component="div"
          count={+totalCount}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={getRowsPerPageOptions()}
        />
      </Stack>

      {viewItem && (
        <View
          open={open}
          modelHandler={modelHandler}
          handleClose={handleClose}
          slug={viewItem}
        />
      )}
    </>
  );
};
export default ProductList;
