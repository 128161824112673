import create from "zustand";
import instanceAxios from "../hooks/axiosConfig";
import { category } from "./module";

type Store = {
  List: category;
  getCategoryList: () => void;
};

const useCategoryStore = create<Store>(
  (set): Store => ({
    List: [],
    getCategoryList() {
      instanceAxios()
        .get("/category/getAllCategory")
        .then((res) => {
          set({ List: res.data.message });
        })
        .catch((error) => {
          // set({ List: error.message });
        });
    },
  })
);
export default useCategoryStore;
