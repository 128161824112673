import CircularProgress from "@mui/material/CircularProgress";
import CenterBox from "../CenterBox";
interface ICircularLoader {
  height?: string;
}
const CircularLoader = ({ height }: ICircularLoader) => {
  return (
    <CenterBox height="100%">
      <img
        src="/Images/Loader/fan (1).png"
        height={height ? height : "24px"}
        className="MuiCircularProgress-indeterminate"
        style={{
          color: "#121212",
          position: "relative",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // marginTop: "-12px",
          // marginLeft: "-12px",
        }}
      />
    </CenterBox>
  );
};
export default CircularLoader;
