import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CenterBox from "../../../core/CenterBox";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "./style";
import SpaceBetween from "../../../core/SpaceBetween";
import useDrawerStore from "../../../store/drawerHandler";

const LeftSide = () => {
  const { open, setOpen } = useDrawerStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));

  const drawerHandler = () => {
    setOpen(open);
  };

  const { classes } = useStyles();
  return (
    <Box
      height={"100%"}
      className={classes.allCategory}
      onClick={drawerHandler}
    >
      <CenterBox height="100%">
        <Link to="#">
          <CenterBox height="100%">
            <Box
              // className={classes.allCategory}
              // onClick={drawerHandler}
              py={0.5}
              px={1}
            >
              <Box className={classes.flex} alignItems={"center"}>
                <Box
                  height="100%"
                  display={"flex"}
                  alignItems={"center"}
                  maxHeight={"100%"}
                >
                  <Box display={"flex"} height={"100%"} gap={"2px"}>
                    <Box>
                      <MenuIcon sx={{ color: "#fff", fontSize: "1.2rem" }} />
                    </Box>
                    <Box>
                      <Typography
                        color={"#fff"}
                        fontWeight={"600"}
                        fontSize={"13px"}
                      >
                        All Category
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CenterBox>
        </Link>
      </CenterBox>
    </Box>
  );
};
export default LeftSide;
