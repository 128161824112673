import { Box, Container } from "@mui/material";
import CenterBox from "../core/CenterBox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonUI from "../core/Button";
import { Link } from "react-router-dom";

const CTM = () => {
  return (
    <Box py={2}>
      <Container maxWidth="lg">
        <Box>
          <Typography variant="h4" textAlign={"center"} gutterBottom py={3}>
            Benefits of Using WORLDQART Cash to Master
          </Typography>
          <Box
            px={{ md: 5 }}
            display={"flex"}
            justifyContent={"space-between"}
            height={"220px"}
          >
            {[1, 2, 3, 4].map((data, index) => {
              return (
                <Box
                  sx={{
                    border: "1px solid #fff",
                    height: "100%",
                    width: "280px",
                    borderRadius: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    p: 2,
                  }}
                >
                  <CenterBox height="100%">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://s3-alpha-sig.figma.com/img/5840/9e3e/f39f0c5ecc84c8a0a2339693ed44cff7?Expires=1699833600&Signature=FAkINjQCzFlsf-6LC940jr-Hbg1RiMx9sfBhdM8-L4Jz2aW6pUYKjP6Y8xq7jYCNLIL01mTfwx35HpK-z3q7nKx6KfQ7KVh-g047pfF-alVq1zVDIrHGxQbVnD92wSh6xgIq6C4qmcWL1w7FKpfHtrUDdJvzA74fdcrFvqsT1UVeQ8brZbdOgZOVFU~KToxT25a~WM4T7dBG56eVNSkbYJOt~29IIKYgHFW3We4yoppT2CZgN1Q2I9FgQ-AExMIAW2iKpwCaDdyUX89k6DCfWqlAUo2wAtdEFIIFBzjDp9JdBSTIigOeIpfczFk-JhCSujKgxCktbIRkSLY9ozpYvg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                        height={"100px"}
                      />
                      <Typography fontSize={"18px"} fontWeight={"600"}>
                        Value for Money
                      </Typography>
                      <Typography fontSize={"14px"}>
                        Provides competitive rates with NO <br />
                        hidden fees
                      </Typography>
                    </div>
                  </CenterBox>
                </Box>
              );
            })}
          </Box>

          <Typography variant="h4" textAlign={"center"} gutterBottom pt={5}>
            How its Work
          </Typography>

          <Box>
            <Box
              sx={{
                backgroundImage: `url('/Images/div.worksWay.png')`,
                height: "500px",
                backgroundSize: "95%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center", // centers the image in the Box
              }}
            ></Box>
          </Box>

          <Box sx={{ minHeight: "350px", background: "#f6f6f6" }}>
            <Typography variant="h4" textAlign={"center"} gutterBottom py={3}>
              Frequently Asked Questions
            </Typography>
            <CenterBox>
              <Box width={"800px"}>
                {[1, 2, 3, 4, 5, 6].map((data, inde) => {
                  return (
                    <Accordion
                      elevation={0}
                      sx={{
                        my: 2,
                        borderRadius: "10px",
                        border: "none",
                        "&&.MuiAccordion-root:before": {
                          backgroundColor: "none",
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          sx={{
                            color: "rgba(0, 80, 161, 1)",
                            fontWeight: "600",
                          }}
                        >
                          Who can apply for Cash to Master?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          All shipowners and ship management companies are
                          eligible to apply for Cash to Master.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <CenterBox>
                  <Box width={"200px"}>
                    <Link to={"/cash-to-master/apply-now"}>
                      <ButtonUI fullWidth>Apply Now</ButtonUI>
                    </Link>
                  </Box>
                </CenterBox>
              </Box>
            </CenterBox>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default CTM;
