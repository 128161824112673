import { Box, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import list from "../../../mockData/header/userDetails/details.json";
const Detail = () => {
  return (
    <Grid
      container
      sx={{ display: "flex", alignItem: "center", justifyContent: "center" }}
    >
      <Grid item md={8}>
        <Box>
          <Box py={2}>
            <Typography variant="h1">{list.title}</Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              {list.list.map((data) => {
                return (
                  <Grid item md={4} pb={2}>
                    <Link to={"/"}>
                      <Box
                        data-card-identifier="YourOrders"
                        sx={{
                          borderRadius: "8px",
                          display: "block",
                          backgroundColor: "#fff",
                          border: "1px #D5D9D9 solid",
                          height: "120px",
                          "&:hover": {
                            background: "#f6f6f6",
                          },
                        }}
                      >
                        <Box py={2}>
                          <Box
                            sx={{
                              borderRadius: "8px",
                              padding: "14px 18px",
                              position: "relative",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  width: "23.448%",
                                  marginRight: "2%",
                                  float: "left",
                                  minHeight: "1px",
                                  overflow: "visible",
                                }}
                              >
                                <img
                                  alt="Your Orders"
                                  src={data.svg}
                                  width={"100%"}
                                ></img>
                              </Box>
                            </Box>
                            <Box>
                              <Typography variant="h2" color={"#000"}>{data.title}</Typography>
                              <Box>
                                <span
                                  style={{ color: "#565959", fontSize: "14px" }}
                                >
                                  {data.subTitle}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
            <Divider />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default Detail;
