import { IconButton, InputBase, Paper } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

interface QuentityEnterInterFace {
  quantity: number;
  increaseClickHandler: () => void;
  decreaseClickHandler: () => void;
  InputHandler: (value: string) => void;
}
const MAX_VALUE = 100000;

const QuentityEnter = ({
  quantity,
  increaseClickHandler,
  decreaseClickHandler,
  InputHandler,
}: QuentityEnterInterFace) => {
  const paperCss = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    borderRadius: "36px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    p: 0,
    m: 0,
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.currentTarget.value.replace(/[^0-9]/g, "");
    let sanitizedValue = numericValue ? parseInt(numericValue, 10) : 0;

    // Ensure value does not exceed maximum
    if (sanitizedValue > MAX_VALUE) {
      sanitizedValue = MAX_VALUE;
    }

    InputHandler(String(sanitizedValue));
  };

  return (
    <Paper component="form" sx={paperCss} elevation={0}>
      <IconButton
        sx={{
          px: "12px",
          borderRight: "1px solid #ccc",
          background: "#f7f7f7",
          borderRadius: "36px 0px 0px 36px",
        }}
        onClick={decreaseClickHandler}
        disabled={quantity > 0 ? false : true}
      >
        <RemoveIcon sx={{ p: 0, m: 0, color: "#000", fontSize: "18px" }} />
      </IconButton>
      <InputBase
        sx={{
          flex: 1,

          "& .MuiInputBase": {
            textAlign: "center",
          },
          "& .MuiInputBase-input": {
            textAlign: "center",
            fontSize: "14px",
            color: "#000",
            fontWeight: "500",
          },
        }}
        placeholder=""
        size="medium"
        inputProps={{
          min: 0,
          max: 99999,
          type: "text",
          inputMode: "numeric", // Allow only numeric input
        }}
        value={quantity > 0 ? quantity : ""}
        type="text"
        onChange={handleInputChange}
      />

      <IconButton
        sx={{
          px: "12px",
          borderRadius: "0px 36px 36px 0px",
          borderLeft: "1px solid #ccc",
          background: "#f7f7f7",
        }}
        aria-label="directions"
        onClick={increaseClickHandler}
      >
        <AddOutlinedIcon sx={{ p: 0, m: 0, color: "#000", fontSize: "18px" }} />
      </IconButton>
    </Paper>
  );
};
export default QuentityEnter;
