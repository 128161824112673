import { Box, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import list from "../../mockData/subHeader/best-seller/productList.json";
import { Small } from "../../core/SlickSettings";
import ProductCard from "../../components/ProductCard";

const BestSellerList = () => {
  const settings = Small();
  return (
    <Box p={2}>
      <Box p={2}>
        <Typography variant="h4">{list.product.title}</Typography>
      </Box>
      <Grid container>
        <Slider {...settings}>
          {list.product.list.map((data, index) => {
            return <ProductCard data={data} key={index} />;
          })}
        </Slider>
      </Grid>
    </Box>
  );
};
export default BestSellerList;
