import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles({})(() => ({
  root: {
    margin: "0 !important",
    padding: "0 !important",
    width: "100%",
    height: "40px ",
  },
  outLineInput: {
    width: "100%",
    height: "100%",
    background: "#fff",
    justifyContent: "space-between",
    alignContent: "start",
    display: "flex",
    borderRadius: "30px",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.Mui-focused": {
      boxShadow: "0 0 0 2px #f90, 0 0 0 3px rgb(255 153 0 / 50%)",
    },
  },
  outLineInputAutoCompleate: {
    // width: "100%",
    // height: "40px",
    // zIndex: 999,

    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    // ".MuiAutocomplete-root": { padding: "0px" },
    // ".MuiAutocomplete-input": { padding: "1px" },
    ".MuiInputBase-input": { border:"1px solid #ff000" },
    // ".MuiButtonBase-root": { padding: "0px" },
    // ".MuiAutocomplete-endAdornment":{padding:"5px"},
  },

  categorySelect: {
    height: "100%",
    borderRadius: "30px 0 0 30px",
    backgroundColor: "#EFEFEF",
    m: "0 !important",
    p: "0 2px !important",
    boxShadow: "none",
    // zIndex: 99999,
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    ".MuiMenuItem-root": {
      fontWeight: "400",
    },
  },
  searchButton: {
    color: "#fff",
    background: "#FBC252",
    zIndex: 999999,
    border: "1px solid #FBC252",
    boxShadow:"none",
    m: 0,
    p: 0,
    height: "100%",
    width: "10px",
    borderRadius: "0px 30px 30px 0px",
    ":hover": {
      background: "#F0ECCF",
      border: 0,
    },
  },
}));
export default useStyles;
