import instanceAxios from "../hooks/axiosConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export class QuotationService {
  public static getQuotationList(portId?: string) {
    const data = {
      portId: "651a7bd1c1447370468a5b3f",
    };
    return instanceAxios().post("/quotation", data, {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }
}
