import {
  Box,
  Grid,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CenterBox from "../../core/CenterBox";
import Title from "./Helper/Title";
import React, { Fragment, useEffect, useState } from "react";
import QuentityEnter from "../../core/QuentityEnter";
import CircularLoader from "../../core/CircularLoader";
import { IProudctList } from "../../models/IProductList";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { CartService } from "../../services/CartServices";
import { IState } from "../../models/IState";
import Tosted from "../../core/Tosted";
import useCallDetailStore from "../../store/Data/callUDetail";
import SpaceBetween from "../../core/SpaceBetween";
import { Delete } from "@mui/icons-material";

interface IProduct {
  data: IProudctList;
  modelHandler: () => void;
  id: number;
}

const MBCard = ({ data, modelHandler, id }: IProduct) => {
  const [quantity, setQuantity] = React.useState(data.quantity);
  const { call, setCall } = useCallDetailStore((state) => ({
    call: state.call,
    setCall: state.setCall,
  }));

  const [state, setState] = useState<IState>({
    loader: false,
    message: "",
    severity: undefined,
    tosted: false,
  });

  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
        setCall(false);
      }, 5000);
    }
  };

  const cartHandler = async (id: string, quantity: number) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.addItemCart(id, quantity);
      setCall(true);
      setState({
        loader: false,
        tosted: true,
        message: "Product Add ",
        severity: "success",
      });
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const removeHandler = async (id: string) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.removeItem(id);
      setCall(true);
      setState({
        loader: false,
        tosted: true,
        message: "Remove An Product",
        severity: "success",
      });
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <Box sx={{ px: 1, py: 1 }}>
        <Box
          sx={{
            height: "300px",
            border: "1px solid #f1f1f1",
            borderRadius: "20px",
            width: "100%",
            p: 1.2,
            background: "#fff",
          }}
        >
          <Box
            sx={{ position: "relative", cursor: "pointer" }}
            height={"100px"}
            onClick={modelHandler}
          >
            <CenterBox height="100%">
              <Box p={1} height={"100%"}>
                <img
                  src={data?.productPicture ? data?.productPicture[0].img : ""}
                  style={{
                    objectFit: "cover",
                    mixBlendMode: "multiply",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </CenterBox>
          </Box>

          <Box>
            <Typography
              textOverflow={"auto"}
              sx={{
                height: "40px",
                overflow: "hidden",
                color: "#000",
                fontWeight: "500",
                fontSize: "14.5px",
                textTransform: "capitalize",
              }}
            >
              {data.name.toLocaleLowerCase()}
            </Typography>
          </Box>
          <SpaceBetween py={1.5}>
            <Typography
              component={"span"}
              color={"#001F3F"}
              fontWeight={"500"}
              fontSize={"14px"}
            >
              IMPA :{" "}
              <Typography
                component={"span"}
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#001F3F"}
              >
                {" "}
                {data.impaCode}
              </Typography>
            </Typography>

            <Typography
              component={"span"}
              color={"#001F3F"}
              fontSize={"14px"}
              fontWeight={"500"}
            >
              UOM :{" "}
              <Typography
                component={"span"}
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#001F3F"}
              >
                {" "}
                {data.uom}
              </Typography>
            </Typography>
          </SpaceBetween>
          <Box>
            <CenterBox height="33px" px="20px">
              <QuentityEnter
                quantity={quantity}
                increaseClickHandler={increaseClickHandler}
                decreaseClickHandler={decreaseClickHandler}
                InputHandler={InputHandler}
              ></QuentityEnter>
            </CenterBox>

            <Box pt={2}>
              {data.quantity > 0 ? (
                <Box sx={{ display: "flex", gap: "3px" }}>
                  <Button
                    variant="contained"
                    disabled={
                      data.quantity !== quantity || quantity === 0
                        ? false
                        : true
                    }
                    onClick={() => cartHandler(data._id, quantity)}
                    sx={{
                      fontSize: "14px",
                      height: "40px",
                      width: "100%",
                      borderRadius: "36px",
                      boxShadow: "none",
                      background: "#003366",
                      color: "#fff",
                      "&:disabled": {
                        background: "none",
                        color: "#003366",
                        boxShadow: "none",
                        textShadow: "none",
                        border: "1px solid #003366",
                        ":before": {
                          boxShadow: "none",
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                  >
                    {!loader ? "Update" : <CircularLoader />}
                  </Button>
                  <IconButton sx={{ width: "40px" }}>
                    <Delete />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  disabled={quantity > 0 ? false : true}
                  onClick={() => cartHandler(data._id, quantity)}
                  sx={{
                    fontSize: "14px",
                    height: "40px",
                    width: "100%",
                    borderRadius: "36px",
                    boxShadow: "none",
                    background: "#F60",
                    color: "#fff",

                    "&:disabled": {
                      background: "none",
                      color: "#ff6600",
                      boxShadow: "none",
                      textShadow: "none",
                      border: "1px solid #ff6600",
                      ":before": {
                        boxShadow: "none",
                      },
                    },
                  }}
                  fullWidth
                  size="small"
                >
                  {!loader ? "Add To Cart" : <CircularLoader />}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MBCard;
