import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "Drawer" })((theme) => ({
  
   clsbtn : {
    height: "28px",
    width: "28px",
    left: "300px",
    background: "transparent",
    zIndex: "999999",
    top: "100px",
    position: "absolute",
    cursor: "pointer",
  }
}));
export default useStyles;
