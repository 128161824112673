import Typography from "@mui/material/Typography";
interface ITitle {
  title: string;
}
const Title = ({ title }: ITitle) => {
  return (
    <Typography variant="h5" color={"#fff"} fontSize={"16px"}>
      {title}
    </Typography>
  );
};
export default Title;
