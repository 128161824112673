import React, { FC, useEffect, useState } from "react";
import { ITreeViewProps } from "../../../models/ICartList";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import RowCart from "./coreCart/RowCart";
import RowItemCart from "./coreCart/RowItemCart";
import Table from "../../../core/Table";
import RenderItems from "./coreCart/RenderItems";
import HeadingItem from "./coreCart/HeadingItem";

const CartList: FC<ITreeViewProps> = ({ data }) => {
  let count = 0;
  const [totalItem, setTotalItems] = useState<number[]>([0]);

  useEffect(() => {
    const mainCategoryItems = data.items.map((item) => {
      const count = item.items.reduce((subCount, subItem) => {
        subCount += subItem.items.reduce((mainCount, mainItem) => {
          mainCount += mainItem.items.length;
          return mainCount;
        }, 0);
        return subCount;
      }, 0);
      return count;
    });
    setTotalItems(mainCategoryItems);
  }, []);

  const [openList, setOpenList] = useState({
    open: false,
    index: 0,
  });

  const updateOpen = (index: number) => {
    if (openList.index === index) {
      setOpenList((prevState) => ({
        open: !prevState.open,
        index,
      }));
    } else {
      setOpenList({
        open: true,
        index,
      });
    }
  };
  

  return (
    <Table>
      <TableHead>
        <RowCart />
      </TableHead>
      <TableBody>
        {data.items.map((cart, index) => {
          return (
            <>
              <HeadingItem
                index={index}
                name={cart.topCategory}
                open={openList}
                setOpen={()=>updateOpen(index)}
                totalItem={totalItem[index]}
              />
              <TableRow>
                <TableCell
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderBottom: "none",
                  }}
                  colSpan={5}
                >
                  <Collapse
                    in={
                      openList.open && openList.index === index ? true : false

                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <Table size="medium" border="1px solid #ccc" my="10px">
                      <TableHead>
                        <RowItemCart />
                      </TableHead>

                      <TableBody>
                        {cart.items.map((category, index) => {
                          return RenderItems(category, index, count);
                        })}
                        <span
                          style={{ display: "none", border: "1px solid #000" }}
                        >
                          {(count = 0)}
                        </span>
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CartList;
