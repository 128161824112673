import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Rate from "../../core/Rating";
import { IProductCard } from "../../models/IProductCard";

const ProductCard = ({ data }: IProductCard) => {
  return (
    <Box
      sx={{
        height: "auto",
        padding: 1,
      }}
    >
      <Box
        sx={{
          height: "200px",
          background: "#fff",
        }}
        px={2}
        py={2}
      >
        <Box
          height="180px"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            pt={0.5}
            sx={{
              position: "absolute",
              top: -20,
              left: -20,
              background: "#c45500",
              height: "30px",
              width: "40px",
              borderRadius: "0% 100% 64% 36% / 100% 0% 100% 0% ",
            }}
          >
            <Typography
              variant="h4"
              color={"#fff"}
              fontSize={"1rem"}
              textAlign={"center"}
            >
              {data.id}
            </Typography>
          </Box>
          <img src={data.img} height={"130px"} />
        </Box>
      </Box>
      <Link to={"#"}>
        <Typography variant="subtitle2">{data.title}</Typography>
      </Link>
      <Link to={"#"}>
        <Rate value={+data.rating} />
      </Link>
    </Box>
  );
};
export default ProductCard;
