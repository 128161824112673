import { Typography, InputBase, Box, FormControl } from "@mui/material";
import { useState } from "react";

interface IProps {
  value: Date | undefined | string;
  disabled?: boolean;
  label: string;
  name: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTogglePassword?: () => void;
  error?: string;
}

const DateField: React.FC<IProps> = ({
  value,
  disabled,
  label,
  handleInputChange,
  error,
  name,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const showPlaceholder = !isFocused && !value;
  const data = value ? value : new Date();

  return (
    <FormControl size="small" fullWidth sx={{ maxWidth: "394px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 0.5,
        }}
      >
        <Typography
          variant="h5"
          color={"#111"}
          fontWeight={700}
          lineHeight={"19px"}
          gutterBottom
          fontSize={"14px"}
        >
          {label}
        </Typography>
      </Box>

      <InputBase
        sx={{
          "&.Mui-focused": {
            boxShadow:
              "0px 0.5px 1px 0.5px rgba(15, 17, 17, 0.15) inset, 0px 0px 0px 3px #C8F3FA",
          },

          "&.MuiInputBase-root": {
            border: "1px solid #ccc",
            borderRadius: "4px",
            height: "40px",
            px: 1,
          },
        }}
        type={isFocused && value !== undefined ? "datetime-local" : "text"}
        disabled={disabled}
        autoSave=""
        autoFocus={true}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        fullWidth
        name={name}
        placeholder="Select Date"
        error={error ? true : false}
        inputProps={{ "aria-label": `${label}` }}
      />

      <Typography
        variant="h6"
        component="span"
        fontWeight={600}
        fontSize={"10px"}
        pl={0}
        color="#ff0000"
      >
        {error}
      </Typography>
    </FormControl>
  );
};
export default DateField;
