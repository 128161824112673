import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "Header" })((theme) => ({
  toolBar: {
    padding: "0px !important",
    margin: 0,
    background: "#0c5273",
    minHeight: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  minHeight: {
    height: "100%",
  },
  Center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  Item: {
    border: "1.5px solid rgba(255, 255, 255, 0.0)",
    "&:hover": {
      border: "1.5px solid rgba(255, 255, 255, 1)",
    },
  },
  allCategory: {
    height:"100%",
    border: "1.5px solid rgba(255, 255, 255, 0.0)",
    "&:hover": {
      border: "1.5px solid rgba(255, 255, 255, 1)",
    },
  },
  flex: {
    display: "flex",
  },
}));
export default useStyles;
