import { Box, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import React from "react";

interface ratingInterface {
  value: number;
}
const Rate = (props: ratingInterface) => {
  return (
    <Box sx={{display:"flex",py:0.2}}>
      <Rating
      name="read-only"
        value={props.value}
        precision={0.5}
        size={"large"}
        color={"#ff9900"}
        readOnly
      />
      {/* <Typography variant="h6" color={"#ff9900"}  sx={{fontSize:"10px"}}>(4000 ratings)</Typography> */}
    </Box>
  );
};
export default Rate;
