import Paper from "@mui/material/Paper";
import Container from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

interface ITable {
  children: React.ReactNode;
  size?: "small" | "medium" | undefined;
  border?: string;
  my?: string;
}

const Table = ({ children, size, border, my }: ITable) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: border ? border : "none",
        background: "#fff",
        borderRadius: "8px",
        my: my,
      }}
    >
      <Container size={size}>{children}</Container>
    </TableContainer>
  );
};
export default Table;
