import React from 'react';
import Page from "../../../components/Page";
import { Location } from "../../../sections/@Header/@userDetail";

// OrderHistory component
const OrderHistory = () => {
  return (
    <Page 
      title="Your Address" 
      description="Your Address">
      <Location/>
    </Page>
  );
};

export default OrderHistory;
