import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IFooterItem } from "../../../models/IFooterItem";
import useStyles from "../styles";

const Item = ({ data }: IFooterItem) => {
  const classes = useStyles();
  return (
    <MenuItem className={classes.menuItems}>
      <Link to={data.path}>
        <Typography variant="subtitle2" py={0.8} fontSize={"14px"} lineHeight={"18px"} color={"#fff"}>
          {data.title}
        </Typography>
      </Link>
    </MenuItem>
  );
};
export default Item;
