import create from "zustand";
type Store = {
  loader: boolean;
  portDetails:
  | {
    id: string;
    ports: string;
    state: string;
    country: string;
  }
  | undefined;
  tosted: {
    message: string;
    status: boolean;
  };
  setLoader: (loader: boolean) => void;
  setTosted: (message: string, status: boolean) => void;
  setPortDetails: (portDetails: {
    id: string;
    ports: string;
    state: string;
    country: string;
  }) => void;
};

const usePortSettingStore = create<Store>(
  (set): Store => ({
    loader: false,
    portDetails: undefined,
    tosted: {
      message: "",
      status: false,
    },
    setLoader(loader) {
      set({ loader: loader });
    },
    setTosted(message, status) {
      set({
        tosted: {
          message,
          status,
        },
      });
    },
    setPortDetails(portDetails) {
      set({ portDetails: portDetails });
    },
  })
);
export default usePortSettingStore;
