import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  toolBar: {
    padding: "0px !important",
    height: "60px",
    width: "100%",
  },
  Height: {
    height: "60px",
  },
});
export default useStyles;
