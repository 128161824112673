import instanceAxios from "../../hooks/axiosConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export class UserService {
  public static getuserdetail() {
    return instanceAxios().get("/user/getuserdetail",{
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }
  public static getRecentSearch(page:number,pageSize:number) {
    return instanceAxios().get("/getRecentProductSearches",{
      params:{
        page:page,
        pageSize:pageSize
      },
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }
}
