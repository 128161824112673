import create from "zustand";

type Store = {
  call: boolean;
  setCall: (call: boolean) => void;
};

const useCallDetailStore = create<Store>(
  (set): Store => ({
    call: false,
    setCall(call) {
      set({ call: call });
    },
  })
);
export default useCallDetailStore;
