import React from "react";
import { Box, Divider, Grid, MenuList } from "@mui/material";
import aboutus from "../../../mockData/footer/aboutUs.json";
import connect from "../../../mockData/footer/connect.json";
import help from "../../../mockData/footer/help.json";
import moneyMaking from "../../../mockData/footer/moneyMaking.json";

import useStyles from "../styles";
import CenterBox from "../../../core/CenterBox";
import Item from "./Item";
import Title from "./Title";
import Bottom from "./Bottom";
import SocialMedia from "../../../components/SocialMedia";

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ background: "#0C5273", position: "relative" }} py={5}>
        <CenterBox height="100%" >
          <Grid container maxWidth={"lg"} className="center">
            <Grid item md={12}>
              <Grid container>
                <Grid item md={2.4}>
                  <Title title={aboutus.title} />
                  <MenuList>
                    {aboutus.List.map((data, index) => {
                      return <Item data={data} key={index} />;
                    })}
                  </MenuList>
                </Grid>
                <Grid item md={2.4}>
                  <Title title={connect.title} />
                  <MenuList>
                    {connect.List.map((data, index) => {
                      return <Item data={data} key={index} />;
                    })}
                  </MenuList>
                </Grid>
                <Grid item md={2.4}>
                  <Title title={help.title} />
                  <MenuList>
                    {help.List.map((data, index) => {
                      return <Item data={data} key={index} />;
                    })}
                  </MenuList>
                </Grid>
                <Grid item md={2.4}>
                  <Title title={moneyMaking.title} />
                  <MenuList>
                    {moneyMaking.List.map((data, index) => {
                      return <Item data={data} key={index} />;
                    })}
                  </MenuList>
                </Grid>
                <Grid item md={2.4}>
                  <Title title={moneyMaking.title} />
                  <MenuList>
                    {moneyMaking.List.map((data, index) => {
                      return <Item data={data} key={index} />;
                    })}
                  </MenuList>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CenterBox>
          <SocialMedia/>
      </Box>

      <Box sx={{ background: "#001F3F", position: "relative" }} >
        <CenterBox height="100%" py={5}>
          <div>
            <Grid
              container
              maxWidth={"lg"}
              className="center"

            >
              <img src="./logo2.png" height={"50px"} />
            </Grid>
            <Bottom />
          </div>
        </CenterBox>
      </Box>
    </>
  );
};

export default Footer;
