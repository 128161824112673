import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CenterBox from "../../../core/CenterBox";
import subHeaderMock from "../../../mockData/subHeader/subHeader.json";
import useStyles from "./style";

const Middle = () => {
  const { classes } = useStyles();
  return (
    <Box display={"flex"}>
      {/* <Grid container sx={{ height: "100%" }}> */}
        {subHeaderMock.map((data, index) => {
          return (
            <Box height={"100%"}>
              <CenterBox height="100%">
                <Link to={data.path}>
                  <CenterBox height="100%">
                    <Box
                      px={1.5}
                      py={0.8}
                      className={classes.Item}
                      sx={{ border: "1px solid transparent" }}
                    >
                      <Typography color={"#fff"} fontWeight={"500"} fontSize={"13px"}>{data.name}</Typography>
                    </Box>
                  </CenterBox>
                </Link>
              </CenterBox>
            </Box>
          );
        })}
      {/* </Grid> */}
    </Box>
  );
};
export default Middle;
