import { ICategory } from "../../../../models/ICartList";
import { Fragment } from "react";
import ItemRowQuotation from "../coreCart/ItemRowQuotation";

const Render = (category: ICategory, id: number, count: number) => {
 
  if ("middleCategory" in category) {
    return (
      <Fragment key={category.middleCategory}>
        {category.items.map((item) => {
          if (item) {
            return (
              <Fragment key={item.subCategory}>
                {item.items.map((data, index) => {
                  count++;
                  return (
                    <ItemRowQuotation
                      data={data}
                      index={index}
                      item={item}
                      category={category}
                    />
                  );
                })}
              </Fragment>
            );
          }
        })}
      </Fragment>
    );
  }
};
export default Render;
