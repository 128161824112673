import create from "zustand";
import { ICategory } from "../../models/ICategory";

interface Store {
    category: ICategory;
    setCategory: (category: ICategory) => void;
}

const useCategoryDetailStore = create<Store>(
    (set): Store => ({
        category: {} as ICategory,
        setCategory(category: ICategory) {
            set({ category: category })
        }
    })
);
export default useCategoryDetailStore;
