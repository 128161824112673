import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  leftBox: { borderRight: "1px solid #ccc", background: "#fff" },
  centerBox: {
    overflowY: "auto",

    borderRadius: "10px",
  },
  rightBox: {
    background: "#fff",
    height: "cover",
    width: "100%",
    borderLeft: "1px solid #ccc",
  },
  center:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  txtHeading:{
    fontWeight:"bold",
    fontSize:"14px",
    color:"#000"
  }
});
export default useStyles;
