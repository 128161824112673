import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1,
    position: "relative",
    height: "100%",
    background: "#fff",
  },
  box: {
    background: "#F9F9F9",
    height: "500px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflowY: "auto",
  },
  tableBox: {
    background: "#fff",
    height: "500px",
    border: "1px solid #ccc",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    borderRadius: "8px",
    overflowY: "auto",
  },
});
export default useStyles;
