import Button from "@mui/material/Button";
import CircularLoader from "../CircularLoader";
import { IButton } from "./interface";

const ButtonUI = ({
  type,
  variant,
  disabled,
  loader,
  children,
  top,
  fullWidth,
}: IButton) => {
  return (
    <Button
      type={type}
      variant={variant}
      disabled={disabled}
      sx={{
        height: "31px",
        py: 2,
        borderRadius: "5px",
        fontSize: "13px",
        fontWeight: "700",
        lineHeight: "29px",
        background: "#F60",
        borderBottom: "1px solid #F60",
        ":hover": {
          background: "#F60",
          borderBottom: "1px solid #F60",
        },
      }}
      fullWidth={fullWidth}
    >
      {loader ? <CircularLoader height="24px" /> : children}
    </Button>
  );
};

export default ButtonUI;
