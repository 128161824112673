import {
  Avatar,
  Box,
  Divider,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import DrawerSearch from "./../../components/DrawerSearch";
import CenterBox from "./../../core/CenterBox";
import { Link } from "react-router-dom";
import usePortSettingStore from "../../store/portSetting";
import useCategoryDetailStore from "../../store/Data/CategoryList";
import useQuery from "../../hooks/useQuery";
import useCategoryHierarchyStore from "../../store/Data/CategoryHierarchy";
import { DrawerIcon, DrawerTitle } from "../../components/DrawerItem";
import { CategoryService } from "../../services/CategoryService";
import { ICategory, MessageEntity } from "../../models/ICategory";
import { Icategoryhierarchy } from "../../models/Icategoryhierarchy";

const DrawerElements = () => {
  const { Port } = usePortSettingStore((state) => ({
    Port: state.portDetails,
  }));
  const query = useQuery();

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchData, setSearchData] = useState<MessageEntity[] | undefined>();
  const [loader, setLoader] = useState(true);

  const [lHierarchy, setIHierarchy] = useState(true);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
    const searchResponse = category.message?.filter((data) => {
      return data.name.toLowerCase().includes(searchInput.toLowerCase());
    });
    setSearchData(searchResponse);
  };

  // New Api Code
  const { category, setCategory } = useCategoryDetailStore((state) => ({
    category: state.category,
    setCategory: state.setCategory,
  }));

  const { categoryhierarchy, setCategoryhierarchy } = useCategoryHierarchyStore(
    (state) => ({
      categoryhierarchy: state.categoryhierarchy,
      setCategoryhierarchy: state.setCategoryhierarchy,
    })
  );

  const getCategoryHandler = async (categoryId?: string) => {
    try {
      setLoader(true);
      setSearchInput("");
      const resCategory = await CategoryService.getCategory(categoryId);
      if (resCategory.status === 200) {
        setLoader(false);
        setCategory(resCategory);

        if (categoryId) {
          gethierarchyHandler(categoryId);
        }
        setLoader(false);
      }
    } catch (error: any) {
      console.log(error.response.message);
      setLoader(false);
      setIHierarchy(false);
    }
  };

  const gethierarchyHandler = async (categoryId?: string) => {
    setIHierarchy(true);
    try {
      const resHierarchy = await CategoryService.getcategoryhierarchy(
        categoryId
      );
      if (resHierarchy.status === 200) {
        setCategoryhierarchy(resHierarchy);
        setLoader(false);
        setIHierarchy(false);
        if (resHierarchy.message?.length === 3) {
          const resCategory = await CategoryService.getCategory(
            resHierarchy.message[1]._id
          );
          if (resCategory.status === 200) {
            setLoader(false);
            setCategory(resCategory);
            setIHierarchy(false);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      alert("Error 181");
      setIHierarchy(false);
      setLoader(false);
    }
  };

  const onClickBackHandler = async (id: number) => {
    if (id === 3) {
      await getCategoryHandler(categoryhierarchy?.message?.[0]?._id);
    } else {
      setCategoryhierarchy({} as Icategoryhierarchy);
      setCategory({} as ICategory);
      await getCategoryHandler();
    }
  };

  useEffect(() => {
    setCategoryhierarchy({} as Icategoryhierarchy);
    if (query.get("impa-catalogue")) {
      gethierarchyHandler(query.get("impa-catalogue")!);
    } else {
      getCategoryHandler();
      setIHierarchy(false);
    }
  }, [query.get("impa-catalogue")]);

  return (
    <Box width={"100%"} height={"100%"}>
      <CenterBox>
        <DrawerSearch
          id={categoryhierarchy.message?.length!}
          onClickBackHandler={(id) => onClickBackHandler(id == 1 ? 0 : id)}
          searchInputHandler={searchInputHandler}
          searchInput={searchInput}
        />
      </CenterBox>
      <CenterBox>
        <Box sx={{ overflowY: "auto", width: "90%" }}>
          <Typography
            variant="h5"
            color={"#000"}
            fontSize={"14px"}
            gutterBottom
            textAlign={"start"}
            pl={1}
            pt={0.5}
          >
            {categoryhierarchy.message?.length! > 0 &&
            categoryhierarchy.message?.length! <= 2
              ? categoryhierarchy.message?.[
                  categoryhierarchy.message?.length - 1
                ]?.name
              : categoryhierarchy.message?.length !== 3
              ? ""
              : categoryhierarchy.message?.[
                  categoryhierarchy.message?.length - 2
                ]?.name}
          </Typography>
          <Divider />
          <List>
            {searchInput.length > 0
              ? searchData?.map((data) =>
                  categoryhierarchy.message?.length! >= 2 ? (
                    <Link
                      to={
                        categoryhierarchy.message?.length! >= 2
                          ? `/category?impa-catalogue=${data._id?.toLowerCase()}`
                          : ""
                      }
                      key={data._id}
                    >
                      <ListItem
                        component="div"
                        disablePadding
                        key={data._id}
                        onClick={() =>
                          categoryhierarchy.message?.length! >= 2
                            ? null
                            : getCategoryHandler(data._id)
                        }
                        sx={{ py: 0.8, px: 0.5 }}
                        selected={
                          data._id.toLocaleLowerCase() ===
                          query.get("impa-catalogue")
                            ? true
                            : false
                        }
                      >
                        <DrawerTitle name={data.name} />
                        <DrawerIcon />
                      </ListItem>
                    </Link>
                  ) : (
                    <ListItem
                      component="div"
                      disablePadding
                      key={data._id}
                      onClick={() =>
                        categoryhierarchy.message?.length! >= 2
                          ? null
                          : getCategoryHandler(data._id)
                      }
                      sx={{ p: 0.8 }}
                      selected={
                        data._id.toLocaleLowerCase() ===
                        query.get("impa-catalogue")
                          ? true
                          : false
                      }
                    >
                      <DrawerTitle name={data.name} />
                      <DrawerIcon />
                    </ListItem>
                  )
                )
              : loader || lHierarchy
              ? [1, 2, 3, 4, 5, 6, 7].map((data) => {
                  return (
                    <Box display={"flex"} gap={"10px"} py={0.8} key={data}>
                      <Skeleton width="100%">
                        <Typography>Loading</Typography>
                      </Skeleton>
                    </Box>
                  );
                })
              : category.message?.map((data) =>
                  categoryhierarchy.message?.length! >= 2 ? (
                    <Link
                      to={
                        categoryhierarchy.message?.length! >= 2
                          ? `/category?impa-catalogue=${data._id?.toLowerCase()}`
                          : ""
                      }
                      key={data._id}
                    >
                      <ListItem
                        component="div"
                        disablePadding
                        key={data._id}
                        onClick={() =>
                          categoryhierarchy.message?.length! >= 2
                            ? null
                            : getCategoryHandler(data._id)
                        }
                        sx={{ p: 0.8 }}
                        selected={
                          data._id.toLocaleLowerCase() ===
                          query.get("impa-catalogue")
                            ? true
                            : false
                        }
                      >
                        <DrawerTitle name={data.name} />
                        <DrawerIcon />
                      </ListItem>
                    </Link>
                  ) : (
                    <ListItem
                      component="div"
                      disablePadding
                      key={data._id}
                      onClick={() =>
                        categoryhierarchy.message?.length! >= 2
                          ? null
                          : getCategoryHandler(data._id)
                      }
                      sx={{ p: 0.8 }}
                      selected={
                        data._id.toLocaleLowerCase() ===
                        query.get("impa-catalogue")
                          ? true
                          : false
                      }
                    >
                      <DrawerTitle name={data.name} />
                      <DrawerIcon />
                    </ListItem>
                  )
                )}
          </List>

          {/* <List>
            {loader || lHierarchy
              ? [1, 2, 3, 4, 5, 6, 7].map((data) => {
                  return (
                    <Box display={"flex"} gap={"10px"} py={0.8} key={data}>
                      <Skeleton width="100%">
                        <Typography>Loading</Typography>
                      </Skeleton>
                    </Box>
                  );
                })
              : category.message?.map((data) =>
                  categoryhierarchy.message?.length! >= 2 ? (
                    <Link
                      to={
                        categoryhierarchy.message?.length! >= 2
                          ? `/category?impa-catalogue=${data._id?.toLowerCase()}`
                          : ""
                      }
                      key={data._id}
                    >
                      <ListItem
                        component="div"
                        disablePadding
                        key={data._id}
                        onClick={() =>
                          categoryhierarchy.message?.length! >= 2
                            ? null
                            : getCategoryHandler(data._id)
                        }
                        sx={{ p: 0.8 }}
                        selected={
                          data._id.toLocaleLowerCase() ===
                          query.get("impa-catalogue")
                            ? true
                            : false
                        }
                      >
                        <DrawerTitle name={data.name} />
                        <DrawerIcon />
                      </ListItem>
                    </Link>
                  ) : (
                    <ListItem
                      component="div"
                      disablePadding
                      key={data._id}
                      onClick={() =>
                        categoryhierarchy.message?.length! >= 2
                          ? null
                          : getCategoryHandler(data._id)
                      }
                      sx={{ p: 0.8 }}
                      selected={
                        data._id.toLocaleLowerCase() ===
                        query.get("impa-catalogue")
                          ? true
                          : false
                      }
                    >
                      <DrawerTitle name={data.name} />
                      <DrawerIcon />
                    </ListItem>
                  )
                )}
          </List> */}
        </Box>
      </CenterBox>
    </Box>
  );
};
export default DrawerElements;
