import { Box, Grid, Typography } from "@mui/material";
import List from "../../../mockData/header/userDetails/address.json"

const Location = () => {
  return (
    <Box>
      <Grid container className="center">
        <Grid item md={8}>
          <Grid container >
            <Box py={2}>
              <Typography variant="h1">{List.title}</Typography>
            </Box>
            <Grid container spacing={2.5}>
                {List.list.map((data)=>{
                    return (
                        <Grid item md={4} > 
                        <Box p={3} sx={{border:"1px solid #C7C7C7",borderRadius:"8px",backgroundColor:"#fff"}}>
                            <Typography variant="h5" color={"#0F1111"} fontSize={"14px"} lineHeight={"25px"}>{data.name}</Typography>  
                            <Typography variant="h6" color={"#0F1111"} fontSize={"14px"} lineHeight={"23px"}>Ship Name: {data.ShipName}</Typography>    
                            <Typography variant="h6" color={"#0F1111"} fontSize={"14px"} lineHeight={"23px"}>Company: {data.Company}</Typography>  
                            <Typography variant="h6" color={"#0F1111"} fontSize={"14px"} lineHeight={"23px"}>Port: {data.Location}</Typography>  
                            <Typography variant="h6" color={"#0F1111"} fontSize={"14px"} lineHeight={"23px"}>{data.phone}</Typography>  
                        </Box>
                    </Grid>                        
                   )
                })}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Location;
