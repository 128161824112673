import ModelOpen from "../../../core/Model";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Product from "../../../components/View";
import { ProductListService } from "../../../services/ProductListService";
import { IState } from "../../../models/IState";
import { IProudctList } from "../../../models/IProductList";
import CircularLoader from "../../../core/CircularLoader";

interface IView {
  open: boolean;
  handleClose: () => void;
  modelHandler: () => void;
  slug: string;
}

const View = ({ open, handleClose, modelHandler, slug }: IView) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [data, setData] = useState<IProudctList>();

  useEffect(() => {
    setData(undefined);
    setState({
      loader: false,
      tosted: false,
      severity: undefined,
      message: "",
    });
    const fetchData = async () => {
      try {
        setState((prevState) => ({ ...prevState, loader: true }));
        const response = await ProductListService.geProductDetail("slug", slug);
        setData(response.data.message);
        setState((prevState) => ({ ...prevState, loader: false }));
      } catch (error: any) {
        setState((prevState) => ({
          ...prevState,
          loader: false,
          severity: "error",
          message: error.response
            ? error.response.data.message
            : "An error occurred while fetching product detail.",
        }));
      }
    };
    fetchData();
  }, [slug]);

  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={modelHandler}
      width={"80%"}
    >
      {data ? (
        <>
          <Box
            sx={{
              height: "30px",
              width: "30px",
              background: "none",
              position: "absolute",
              right: "-50px",
              top: "-25px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "#fff" }} fontSize={"large"} />
          </Box>
          <Product data={data} />
        </>
      ) : (
        <CircularLoader />
      )}
    </ModelOpen>
  );
};
export default View;
