import instanceAxios from "../hooks/axiosConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export class PortService {
  public static getPortDetailbyId(portId: string) {
    return instanceAxios().get("/port/getPortDetail", {
      params: {
        portId: portId,
      },
    });
  }
  public static selectport(portId: string) {
    const data = {
      port: portId,
    };
    return instanceAxios().post("/port/selectport", data, {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }
}
