import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
const RowCart = () => {
  return (
    <TableRow sx={{ height: "35px", background: "#d2daff" }}>
      <TableCell
        colSpan={2}
        variant="head"
        size="small"
        align="left"
        sx={{ color: "#000", fontSize: "14px" }}
      >
        Category
      </TableCell>
      <TableCell
        variant="head"
        size="small"
        colSpan={2}
        align="right"
        sx={{ color: "#000", pr: 3, fontSize: "14px" }}
      >
        Quatity
      </TableCell>
    </TableRow>
  );
};
export default RowCart;
