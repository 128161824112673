import create from "zustand";
import { Icategoryhierarchy } from "../../models/Icategoryhierarchy";

interface Store {
    categoryhierarchy: Icategoryhierarchy;
    setCategoryhierarchy: (category: Icategoryhierarchy) => void;
}

const useCategoryHierarchyStore = create<Store>(
    (set): Store => ({
        categoryhierarchy: {} as Icategoryhierarchy,
        setCategoryhierarchy(categoryhierarchy: Icategoryhierarchy) {
            set({ categoryhierarchy: categoryhierarchy })
        }
    })
);
export default useCategoryHierarchyStore;
