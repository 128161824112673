import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ListItemIcon } from "@mui/material";
import CenterBox from "../../core/CenterBox";
const DrawerIcon = () => {
  return (
    <ListItemIcon sx={{justifyContent: 'right'}}>
      <NavigateNextIcon fontSize="large"  />
    </ListItemIcon>
  );
};

export default DrawerIcon;
