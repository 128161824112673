import create from "zustand";
import { IPortDetail } from "../../models/IPortDetails";

interface Store {
  portDetail: IPortDetail;
  setPortDetail: (portDetail: IPortDetail) => void;
}

const usePortDetailStore = create<Store>(
  (set): Store => ({
    portDetail: {} as IPortDetail,
    setPortDetail(portDetail: IPortDetail) {
      set({ portDetail: portDetail });
    },
  })
);
export default usePortDetailStore;
