import { Box, Container, Toolbar } from "@mui/material";
import useStyles from "./styles";
import AppBar from "../../../core/AppBar";
import { lazy, Suspense } from "react";
import CircularLoader from "../../../core/CircularLoader";
import { useScrollTrigger } from "@mui/material";
import LanguageModal from "./components/Model/Language";
import AccountModal from "./components/Model/Account";
import useAccElementStore from "../../../store/Data/AccountModel";
import useBackDropStore from "../../../store/UI/Backdrop";
const Main = lazy(() => import("./Main"));

function Header() {
  const classes = useStyles();

  const { accElement, accOpen, setAccElement, setAccOpen } = useAccElementStore(
    (state) => ({
      accElement: state.accElement,
      accOpen: state.accOpen,
      setAccElement: state.setAccElement,
      setAccOpen: state.setAccOpen,
    })
  );

  const { open, setOpen } = useBackDropStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100, // When the user scrolls 100px down, the button will appear.
  });

  const handleCloseAccount = () => {
    setAccOpen(false);
    setOpen(false);
    setAccElement(null);
  };

  return (
    <>
      <AppBar color="#001f3f" zIndex={3}>
        <Toolbar
          className={`${classes.toolBar}`}
          sx={{
            position: trigger ? "fixed" : "relative",
            background: "#001f3f",
          }}
        >
          <Container
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              gap: "10px",
              marginX: "10px",
            }}
            maxWidth={false}
          >
            <Suspense fallback={<CircularLoader />}>
              <Main />
            </Suspense>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        width={"100%"}
        sx={{
          position: "relative",
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
      >
        {/* {openLanguage && (
          <LanguageModal
            open={openLanguage}
            handleClose={handleCloselanguage}
            anchorEl={anchorElLanguage}
          />
        )} */}

        <AccountModal
          open={accOpen}
          handleClose={handleCloseAccount}
          anchorEl={accElement}
        />
      </Box>
    </>
  );
}

export default Header;
