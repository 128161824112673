import Typography from "@mui/material/Typography";
import "./CategoryList.css";
const CategoryList = () => {
  return (
    <>
      <ul className="carousel x-slide">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((data) => {
          return (
            <li className="item">
              <div className="content-image">
                <img src="https://m.media-amazon.com/images/I/110goaYwsDL._SX100_SY100_.jpg" />
              </div>
              <Typography variant="h6" color={"#000"} textAlign={"center"}>
                Mobile
              </Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CategoryList;
