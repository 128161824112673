import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import { CartList, ViewQuotation } from "./pages/paymentProcess";
import { BestSeller, Category } from "./pages/subHeaderPages";
import {
  Address,
  OrderHistory,
  TrackOrder,
  YourAccont,
  Profile,
} from "./pages/headerPages/userDetails";
import Quatation from "./pages/paymentProcess/Quatation";
import ProtectedRoute from "./components/ProtectedRoute";

// mobile Device Routes
import MDHome from "./UI/mobile/pages/Home";
import MDMain from "./UI/mobile/pages/Main";
import SearchList from "./pages/productList/SerachList";
import HomeRoute from "./components/ProtectedRoute/HomeRoute";
import CenterBox from "./core/CenterBox";
import CircularLoader from "./core/CircularLoader";
import CTM from "./pages/CTM";
import CTMApplyNow from "./pages/CTMApplyNow";

const OtpVarification = lazy(() => import("./pages/auth/OtpVarification"));
const Login = lazy(() => import("./pages/auth/Login"));
const ForgetPassword = lazy(() => import("./pages/auth/ForgetPassword"));
const Main = lazy(() => import("./pages/Main"));
const Product = lazy(() => import("./pages/Product"));
const ProductList = lazy(() => import("./pages/productList/index"));
const Home = lazy(() => import("./pages/Home/index"));
const EmailLogin = lazy(() => import("./pages/auth/EmailLogin"));
const ChangePassword = lazy(() => import("./pages/auth/ChangePassword"));
const RFQ = lazy(() => import("./pages/RFQ"));

const Path = () => {
  return (
    <Suspense
      fallback={
        <CenterBox height="100vh">
          <CircularLoader height="60px" />
        </CenterBox>
      }
    >
      <Routes>
        {/* AUTH SECTION  */}

        <Route path="/login/:email" element={<Login />} />

        <Route path="/changepassword" element={<ChangePassword />} />

        <Route path="/login" element={<EmailLogin />} />
        {/* <Route path="/signup" element={<Signup />}></Route> */}
        <Route path="/signup/otp" element={<OtpVarification />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route
          path="/forget-password/otp"
          element={<OtpVarification />}
        ></Route>
        {/* END SECTION  */}

        <Route element={<HomeRoute />}>
          <Route path="/" element={<Main />}>
            <Route index element={<Home />} />

            <Route path="/request-for-quotation" element={<RFQ />} />
            <Route path="/cash-to-master" element={<CTM />} />
            <Route path="/cash-to-master/apply-now" element={<CTMApplyNow />} />

            <Route path="/category" element={<ProductList />} />
            <Route path="/search" element={<SearchList />} />
            <Route path="/product" element={<Product />} />
            <Route path="/best-seller/in" element={<BestSeller />} />
            <Route path="/:category/in" element={<Category />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/your-account" element={<YourAccont />} />
          <Route
            path="/your-account/order-history"
            element={<OrderHistory />}
          />
          <Route path="/your-account/profile" element={<Profile />} />
          <Route path="/your-account/addresses" element={<Address />} />
          <Route path="/your-account/track-order" element={<TrackOrder />} />
          <Route path="/cart" element={<CartList />}></Route>
          <Route path="/quotation" element={<Quatation />}></Route>
          <Route path="/quotation/:id" element={<ViewQuotation />}></Route>
        </Route>

        {/* Routes for Mobile Devices  */}
        <Route path="/mobile" element={<MDMain />}>
          <Route index element={<MDHome />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default Path;
