import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  menuItems: {
    "&.MuiMenuItem-root": {
      padding: "2px 0px",
      "&:hover": {
        h6: {
          color: "#fff",
        },
        background: "none",
      },
    },
  },
  Language: {
    position: "absolute",
    background: "#fff",
    height: "auto",
    width: "200px",
    right: "18%",
    top: "100%",
    zIndex: 1,
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
  acc: {
    position: "absolute",
    background: "#fff",
    height: "auto",
    width: "450px",
    right: "30%",
    top: "100%",
    zIndex: 1,
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
});
export default useStyles;
