import instanceAxios from "../hooks/axiosConfig";
import { ICategory } from "../models/ICategory";
import { Icategoryhierarchy } from "../models/Icategoryhierarchy";

export class CategoryService {
  public static async getCategory(categoryId?: string):Promise<ICategory> {
    try {
      const response = await instanceAxios().get("/category/getCategory", {
        params: {
          categoryId: categoryId,
        },
      });
      return response.data;
    } catch (error) {
      // Handle error (e.g., log or throw)
      throw error;
    }
  }

  public static async getcategoryhierarchy(categoryId?: string):Promise<Icategoryhierarchy> {
    try {
      const response = await instanceAxios().get(
        "/category/getcategoryhierarchy",
        {
          params: {
            categoryId: categoryId,
          },
        }
      );
      return response.data;
    } catch (error) {
      // Handle error (e.g., log or throw)
      throw error;
    }
  }
}
