import { Box, Divider, Grid, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import list from "../../../mockData/header/userDetails/order.json";
import SpaceBetween from "../../../core/SpaceBetween";

const Order = () => {
  console.log("list is ", list);

  const style = {
    height: 250,
    bgcolor: "#fff",
    border: "1px #D5D9D9 solid",
    borderRadius: "8px",
    zIndex: "99999",
  };
  const title = {
    borderBottom: "1px solid #D5D9D9",
    backgroundColor: "#F0F2F2",
    borderRadius: "8px 8px 0 0",
    padding: 2,
    color: "#fff",
  };
  const subtitle = {
    padding: 3,
  };

  const heading = {
    fontSize: "12px",
    lineHeight: "16px",
  };

  return (
    <Box>
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <Grid item md={8}>
            <Box>
              <Box py={2}>
                <Typography variant="h1">{"Your Order"}</Typography>
              </Box>
              <Box py={0}>
                <ul style={{ display: "flex", padding: 0, marginTop: "10px" }}>
                  {list.menu.map((data) => {
                    return (
                      <ListItem
                        sx={{
                          color: "#448ccb",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {data.title}
                      </ListItem>
                    );
                  })}
                </ul>
                <Divider />
                <Box p={2}>
                  {list.orderList.map((data) => {
                    return (
                      <Box py={2}>
                        <Box sx={style}>
                          <Box sx={title}>
                            <SpaceBetween>
                              <Box>
                                <Box sx={{ display: "flex" }}>
                                  <Box px={2}>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "12px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        Order placed
                                      </span>
                                    </div>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        {data.date}
                                      </span>
                                    </div>
                                  </Box>
                                  <Box px={2}>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "12px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        Total
                                      </span>
                                    </div>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        ${data.Cost}.00
                                      </span>
                                    </div>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Box px={2}>
                                  <div style={heading}>
                                    <span
                                      style={{
                                        color: "#565959",
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      Order # {data.orderId}
                                    </span>
                                  </div>
                                  <div style={heading}>
                                    <span
                                      style={{
                                        color: "#565959",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {data.date}
                                    </span>
                                  </div>
                                </Box>
                              </Box>
                            </SpaceBetween>
                          </Box>
                          <Box p={2}>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "18px",
                                  lineHeight: "24px",
                                  color: "#0F1111",
                                }}
                              >
                                SucessFully
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#0F1111",
                                }}
                              >
                                Paid On: {data.date}{" "}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Order;
