import instanceAxios from "../hooks/axiosConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export class CartService {
  public static getTotalCartItems() {
    return instanceAxios().get("/user/cart/getTotalCartItems", {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }
  public static getCartList() {
    return instanceAxios().get("/user/cart/getCartItems", {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }

  public static addItemCart(
    product: string,
    quantity: number,
    remark?: string
  ) {
    const data = {
      productid: product,
      quantity: quantity,
      remark: remark,
    };
    return instanceAxios().post("/user/cart/addItemToCart", data, {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }

  public static getCartItems() {
    return instanceAxios().get("/user/cart/getCartItems", {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }

  public static removeItem(id:string) {
    const data = {
      productId: id
    };
    return instanceAxios().post("/user/cart/removeItem",data, {
      headers: {
        Authorization: `Bearer ${cookies.get("user-token")}`,
      },
    });
  }

}
