import { Box } from "@mui/material";
import { ICard } from "./interface";

const NormalCard=({children}:ICard)=>{
    return(
        
         <Box
            sx={{
              height: "100%",
              boxShadow:
                "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px",
            }}
            my={1}
            p={2}
          >
            {children}
          </Box>
        
    )
}
export default NormalCard;