import styled from "@emotion/styled";

import worldquart from "./logo.png"
function Logo() {
  const Logo = styled("img")(() => ({
    // width: "95px",
    height:"40px"
  }));
  return <Logo src={"/logo.png"} alt={" Logo"} loading="lazy"   />;
}
export default Logo;
