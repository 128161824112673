import React, { useEffect, useState } from "react";
import {
  ICategory,
  IProduct,
  IMainCategory,
} from "../../../../models/ICartList";
import { TableRow, TableCell, Button, Box } from "@mui/material";
import QuentityEnter from "../../../../core/QuentityEnter";
import View from "../../../productList/components/View";
import { IState } from "../../../../models/IState";
import useCallDetailStore from "../../../../store/Data/callUDetail";
import { CartService } from "../../../../services/CartServices";
import CircularLoader from "../../../../core/CircularLoader";
import Tosted from "../../../../core/Tosted";

interface ItemRowProps {
  data: IProduct;
  index: number;
  item: IMainCategory;
  category: ICategory;
}

const ItemRow: React.FC<ItemRowProps> = ({ data, index, item, category }) => {
  const { setCall } = useCallDetailStore((state) => ({
    setCall: state.setCall,
  }));
  const cellStylesubTableBody = {
    fontWeight: "500",
    borderBottom: "none",
    fontSize: "14px",
    padding: "8px 10px",
    textTransform: "capitalize",
    cursor: "pointer",
  };

  const [state, setState] = useState<IState>({
    loader: false,
    message: "",
    severity: undefined,
    tosted: false,
  });

  const [open, setOpen] = useState(false);
  const [viewItem, setViewItem] = useState<string>();

  const modelHandler = () => {
    setOpen(!open);
  };
  const HModalClose = () => {
    setOpen(false);
  };

  const onViewHandler = (slug: string) => {
    setViewItem(slug);
    modelHandler();
  };

  const [quantity, setQuantity] = useState(data.quantity);
  const [update, setUpdate] = useState(false);
  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => +value);
    }
  };

  useEffect(() => {
    if (quantity < data.quantity || quantity > data.quantity) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  }, [quantity]);
  const { loader, tosted, message, severity } = state;
  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };

  const cartHandler = async (id: string, quantity: number) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.addItemCart(id, quantity);
      setState({
        loader: false,
        tosted: true,
        message: "Product Add ",
        severity: "success",
      });
      setCall(true);
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const removeHandler = async (id: string) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.removeItem(id);
      setState({
        loader: false,
        tosted: true,
        message: "Remove An Product",
        severity: "success",
      });
      setCall(true);
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  // The JSX you had inside your map for item.items
  return (
    <>
      <TableRow
        key={index + 1}
        sx={{
          borderBottom: item.items.length === index ? "none" : "1px solid #ccc",
          "& .MuiTableRow-root": {
            borderBottom: "1px solid #ccc",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={cellStylesubTableBody}
          align="left"
          colSpan={6}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            onClick={() => onViewHandler(data.name)}
          >
            <img
              src={data.productPicture[0].img}
              style={{
                objectFit: "cover",
                mixBlendMode: "multiply",
                height: "30px",
                width: "30px",
                borderRadius: "10px",
              }}
            />
            {data.name}
          </Box>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          colSpan={2}
          sx={cellStylesubTableBody}
        >
          {data.impaCode}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          width={"170px"}
          sx={cellStylesubTableBody}
        >
          <Box display={"flex"} justifyContent={"right"}>
            <QuentityEnter
              quantity={quantity}
              increaseClickHandler={increaseClickHandler}
              decreaseClickHandler={decreaseClickHandler}
              InputHandler={InputHandler}
            ></QuentityEnter>
          </Box>
        </TableCell>

        <TableCell sx={cellStylesubTableBody} colSpan={2} align="center">
          {quantity !== 0 && quantity === data.quantity ? (
            <Button
              variant="outlined"
              sx={{ fontSize: "10px", height: "35px", width: "100%" }}
              onClick={() => removeHandler(data._id)}
            >
              {!loader ? "Remove" : <CircularLoader />}
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={quantity > 0 ? false : true}
              onClick={() => cartHandler(data._id, quantity)}
              sx={{ fontSize: "10px", height: "35px", width: "100%" }}
              fullWidth
              size="small"
            >
              {!loader ? "Add To Cart" : <CircularLoader />}
            </Button>
          )}
        </TableCell>
      </TableRow>

      {viewItem && (
        <View
          open={open}
          modelHandler={modelHandler}
          handleClose={HModalClose}
          slug={data.slug}
        />
      )}

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default ItemRow;
