import Page from "../../components/Page";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelOpen from "../../core/Model";
import CenterBox from "../../core/CenterBox";
import { Link } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { ITreeViewApiProps } from "../../models/ICartList";
import List from "./Helper/CartList";
import SpaceBetween from "../../core/SpaceBetween";
import Head from "../../core/HeadLine";
import Container from "../../core/Container";
import useTotalCartItemStore from "../../store/Data/totalCartItem";
import { CartService } from "../../services/CartServices";
import Cookies from "universal-cookie";
// import useSt from "./Styles.ts"
import useStyles from "./styles";
import Request from "../../core/Request";
import { IState } from "../../models/IState";
import CircularLoader from "../../core/CircularLoader";
import useCallDetailStore from "../../store/Data/callUDetail";
const cookies = new Cookies();

const Cart = () => {
  const classes = useStyles();
  const [state, setState] = useState<IState>({
    loader: false,
    severity: undefined,
    tosted: false,
    message: "",
  });

  const { call, setCall } = useCallDetailStore((state) => ({
    call: state.call,
    setCall: state.setCall,
  }));

  const [list, setList] = useState<ITreeViewApiProps | undefined>();
  const [open, setOpen] = React.useState(false);
  const [modelDetails, setModelDetails] = React.useState({
    subCategory: "",
    title: "",
    quentity: "",
  });

  const modelHandler = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCartList = async () => {
    try {
      setState((prevState) => ({ ...prevState, loader: true }));
      await CartService.getCartList()
        .then((res) => {
          console.log(res);
          setList(res.data.message);
          setState((prevState) => ({
            ...prevState,
            loader: false,
            tosted: true,
            message: "Success",
            severity: "success",
          }));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            loader: false,
            tosted: true,
            message: error.response?.data?.message || "An error occurred",
            severity: "error",
          }));
        });
    } catch (error: any) {
      setState((prevState) => ({
        ...prevState,
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      }));
    }
  };

  const { totalCartItems } = useTotalCartItemStore((state) => ({
    totalCartItems: state.totalCartItems,
  }));

  useEffect(() => {
    setList(undefined);
    fetchCartList();
  }, []);

  useEffect(() => {
    if (call === true) {
      setList(undefined);
      fetchCartList();
      setCall(false);
    }
  }, [call]);

  const HStateClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };

  const { loader, tosted, message, severity } = state;

  useEffect(() => {
    HStateClose();
  }, [tosted]);

  return (
    <Page title="Cart List" description="">
      <Container>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            position: "relative",
            height: "100%",
            background: "#fff",
          }}
        >
          <Grid container className="center" spacing={2}>
            <Grid item md={8}>
              <SpaceBetween>
                <Head
                  title="Your Cart List"
                  subTitle="Your Order summary"
                ></Head>
              </SpaceBetween>

              <Box py={2}>
                <Box className={classes.tableBox}>
                  <Box
                    sx={{
                      height: "100%",
                    }}
                  >
                    {!loader && list ? (
                      <List data={list} />
                    ) : (
                      <CircularLoader />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3.5}>
              <Request
                title="Request for Quotation"
                subTitle="Your Order Is Eligible For FREE Delivery"
                TItem={totalCartItems}
                url="/quotation"
                BTitle="Check Now"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={modelHandler}
      >
        <Paper sx={{ width: "90%", p: 2, borderRadius: "16px" }} elevation={3}>
          <Box px={2}>
            <Typography variant="h4" lineHeight={"45px"}>
              {modelDetails.subCategory}
            </Typography>
            <Typography variant="h6" color={"#000"}>
              {modelDetails.title}
            </Typography>
            <Box py={1}>
              <CenterBox height="100%">
                <Box sx={{ width: "50%" }}></Box>
              </CenterBox>
            </Box>
          </Box>
          <Box textAlign={"center"} sx={{ p: 2 }}>
            <Button
              variant="contained"
              fullWidth
              sx={{ fontSize: "13px", textTransform: "capitalize" }}
            >
              Confirm Now
            </Button>
          </Box>
        </Paper>
      </ModelOpen>
    </Page>
  );
};
export default Cart;
