import { Box,Paper ,Typography,Button} from "@mui/material";
import { Link } from "react-router-dom";
interface IRequest{
    TItem:number,
    title:string,
    subTitle:string,
    BTitle:string,
    url:string
}

const Request = ({TItem,title,subTitle,BTitle,url}:IRequest) => {
  return (
    <Box
      mt={2}
      sx={{
        height: "400px",
        width: "100%",
      }}
      className={"center"}
    >
      <Paper sx={{ width: "90%", p: 2, borderRadius: "16px" }} elevation={3}>
        <Box px={2}>
          <Typography variant="h4" lineHeight={"45px"}>
            {title}
          </Typography>
          <Typography variant="h6" color={"#000"}>
            {subTitle}
          </Typography>
          <Box py={1}>
            <Typography variant="h2" lineHeight={"35px"}>
              Total ({TItem} Items)
            </Typography>
          </Box>
        </Box>
        <Box textAlign={"center"} sx={{ p: 2 }}>
          <Link to={`${url}`}>
            <Button
              variant="contained"
              fullWidth
              sx={{ fontSize: "13px", textTransform: "capitalize" }}
            >
              {BTitle}
            </Button>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};
export default Request;
