import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer, Header } from "../../sections/@Layout";
import useLoginStore from "../../store/login";
import Backdrop from "../../core/Backdrop";
import useBackDropStore from "../../store/UI/Backdrop";
import { UserService } from "../../services/user/userdetail";
import useUserDetailStore from "../../store/Data/userDetail";
import usePortIdStore from "../../store/Data/portId";
import { PortService } from "../../services/PortService";
import usePortDetailStore from "../../store/Data/portDetail";
import useTotalCartItemStore from "../../store/Data/totalCartItem";
import usePortModelStore from "../../store/UI/PortModel";
import useCallDetailStore from "../../store/Data/callUDetail";

const ProtectedRoute: React.FC = () => {
  const { token, setToken } = useLoginStore((state) => ({
    token: state.token,
    setToken: state.setToken,
  }));
  const cookies = new Cookies();
  const [apiError, setApiError] = useState(false);

  // Routes setup
  const location = useLocation();

  const { open, setOpen } = useBackDropStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));

  const { call, setCall } = useCallDetailStore((state) => ({
    call: state.call,
    setCall: state.setCall,
  }));

  const { data, setData } = useUserDetailStore((state) => ({
    data: state.data,
    setData: state.setData,
  }));

  const { openModel, setOpenmodel } = usePortModelStore((state) => ({
    openModel: state.open,
    setOpenmodel: state.setOpen,
  }));

  const { portId, setPortId } = usePortIdStore((state) => ({
    portId: state.portId,
    setPortId: state.setPortId,
  }));

  const { portDetail, setPortDetail } = usePortDetailStore((state) => ({
    portDetail: state.portDetail,
    setPortDetail: state.setPortDetail,
  }));

  const { totalCartItem, setTotalCartItems } = useTotalCartItemStore(
    (state) => ({
      totalCartItem: state.totalCartItems,
      setTotalCartItems: state.setTotalCartItems,
    })
  );

  useEffect(() => {
    setToken(cookies.get("user-token"));
    const handleStorage = (event: StorageEvent) => {
      if (event.key === "user-token") {
        setToken(event.newValue);
      }
    };
    window.addEventListener("storage", handleStorage);
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (cookies.get("user-token")) {
      const response = UserService.getuserdetail();
      response
        .then((res) => {
          setData(res.data);

          setPortId(
            res.data.selectedPort !== "" ||
              (res.data.message.selectedPort !== undefined &&
                res.data.selectedPort)
          );
          setTotalCartItems(res.data.message.totalCartItems);
          if (
            res.data.message.selectedPort !== "" ||
            res.data.message.selectedPort !== undefined
          ) {
            getPortDetails(res.data.message.selectedPort);
            setOpenmodel(false);
          } else {
            setOpenmodel(true);
          }
        })
        .catch((error) => {
          setApiError(true);
        });
    } else {
      setApiError(true);
    }
  }, [cookies.get("user-token")]);

  useEffect(() => {
    if (cookies.get("user-token") && call === true) {
      const response = UserService.getuserdetail();
      response
        .then((res) => {
          setData(res.data);
          setPortId(res.data.selectedPort);
          setTotalCartItems(res.data.message.totalCartItems);
          if (res.data.message.selectedPort !== "") {
            getPortDetails(res.data.message.selectedPort);
            setOpenmodel(false);
          } else {
            setOpenmodel(true);
          }
        })
        .catch((error) => {
          setApiError(true);
        });
    }
  }, [call]);

  const getPortDetails = async (id: string) => {
    try {
      const response = await PortService.getPortDetailbyId(id);
      setPortDetail(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  return cookies.get("user-token") && !apiError ? (
    <Fragment>
      <Header />
      <Backdrop open={open}></Backdrop>
      <Outlet />
      <Footer />
    </Fragment>
  ) : (
    <Navigate to={"/login"} replace={true} state={location} />
  );
};

export default ProtectedRoute;
