import create from "zustand";
type Store = {
  portId: string | null | undefined;
  setPortId: (portId: string | null) => void;
};

const usePortIdStore = create<Store>(
  (set): Store => ({
    portId: "",
    setPortId(portId) {
      set({ portId: portId });
    },
  })
);
export default usePortIdStore;
