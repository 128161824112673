import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./style";

interface DrawerSearchInterFace {
  id: number;
  searchInput: string;
  onClickBackHandler: (id: number) => void;
  searchInputHandler: (value: string) => void;
}

export default function DrawerSearch({
  id,
  onClickBackHandler,
  searchInputHandler,
  searchInput,
}: DrawerSearchInterFace) {
  const { classes } = useStyles();
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "90%",
        my: 1,
        justifyContent: "center",
        borderRadius: "10px",
      }}
      className={classes.elevation2}
      elevation={3}
    >
      {id >= 0 && (
        <>
          <IconButton
            sx={{ p: "10px" }}
            color="primary"
            aria-label="back item"
            onClick={() => onClickBackHandler(id)}
          >
            <ArrowBackIosIcon sx={{ color: "#ff9900" }} />
          </IconButton>
        </>
      )}

      <InputBase
        sx={{ ml: 1, flex: 1, p: 0.5 }}
        placeholder={`Search`}
        size="medium"
        inputProps={{ "aria-label": "Search Category" }}
        onChange={(e) => {
          searchInputHandler(e.target.value);
        }}
        value={searchInput}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

      <IconButton sx={{ p: "10px" }} aria-label="directions">
        <SearchIcon sx={{ color: "#ff9900" }}  />
      </IconButton>
    </Paper>
  );
}
