import React, { useEffect, useState } from "react";
import { ICategory } from "../../../../models/ICartList";
import { TableRow, TableCell, Button, Box } from "@mui/material";
import QuentityEnter from "../../../../core/QuentityEnter";
import View from "../../../productList/components/View";

interface ItemRowProps {
  data: any;
  index: number;
  item: any;
  category: ICategory;
}

const ItemRowQuotation: React.FC<ItemRowProps> = ({
  data,
  index,
  item,
  category,
}) => {
  const cellStylesubTableBody = {
    fontWeight: "500",
    borderBottom: "none",
    fontSize: "14px",
    padding: "8px 10px",
    textTransform: "capitalize",
    cursor: "pointer",
  };

  const [open, setOpen] = useState(false);
  const [viewItem, setViewItem] = useState<string>();

  const modelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onViewHandler = (slug: string) => {
    setViewItem(slug);
    modelHandler();
  };

  const [quantity, setQuantity] = useState(+data.quantity);
  const [update, setUpdate] = useState(false);
  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => +value);
    }
  };

  useEffect(() => {
    if (quantity < data.quantity || quantity > data.quantity) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  }, [quantity]);

  // The JSX you had inside your map for item.items
  return (
    <>
      <TableRow
        key={index + 1}
        sx={{
          borderBottom: item.items.length === index ? "none" : "1px solid #ccc",
          "& .MuiTableRow-root": {
            borderBottom: "1px solid #ccc",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={cellStylesubTableBody}
          align="left"
          colSpan={6}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            onClick={() => onViewHandler(data.name)}
          >
            <img
              src={
                "https://www.shipserv.com/ShipServ/pages/catalogues/231092/47413/005407.JPG"
              }
              style={{
                objectFit: "cover",
                mixBlendMode: "multiply",
                height: "30px",
                width: "30px",
                borderRadius: "10px",
              }}
            />
            {data.name}
          </Box>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          colSpan={2}
          sx={cellStylesubTableBody}
        >
          {data.impaCode}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          width={"170px"}
        //   colSpan={2}
          sx={cellStylesubTableBody}
        >
          <Box display={"flex"} justifyContent={"right"}>
            <QuentityEnter
              quantity={quantity}
              increaseClickHandler={increaseClickHandler}
              decreaseClickHandler={decreaseClickHandler}
              InputHandler={InputHandler}
            ></QuentityEnter>
          </Box>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={cellStylesubTableBody}
        >
          {data.price}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          align="center"

          sx={cellStylesubTableBody}
        >
          {data.value}
        </TableCell>
      </TableRow>

      {viewItem && (
        <View
          open={open}
          modelHandler={modelHandler}
          handleClose={handleClose}
          slug={"BOOTS-WORKING-ANTI-ELECTRO-STATIC-27.5CM"}
        />
      )}
    </>
  );
};
export default ItemRowQuotation;
