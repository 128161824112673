import Grid from "@mui/material/Grid";
import Page from "../../components/Page";
import { List } from "./components";
import Container from "../../core/Container";
import { useState } from "react";

const SearchList = () => {
  const [show, setShow] = useState(false);
  return (
    <Page
      title="Clothing for Men online : Best brands at best prices only on Test.in"
      description="Men's clothing online : Shop the largest branded collection for Men from Allen Solly, Levis, Van Heusen, Peter England, Levis, USPA, Puma & more .Choose from a wide range of casual and formal styles, denims, jackets, t-shirts, polos, jeans and more at lowest prices and biggest discounts on Amazon.in"
    >
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#eaeded",
          }}
          mt={1}
        >
          <Grid item md={7.8} p={1.5} sx={{ background: "#eaeded" }}>
            <List show={show} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default SearchList;
