import Page from "../../../components/Page";
import TopNav from "../sections/@Layout/TopNav";
import Box from "@mui/material/Box";
import CategoryList from "../sections/@Home/CategoryList";

const MDMain = () => {
  return (
    <Box>
      <TopNav />
      <CategoryList />
    </Box>
  );
};
export default MDMain;
