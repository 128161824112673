import { Grid } from "@mui/material";

const RightSide = () => {
  return (
    <Grid item md={3}>
      {/* <img
        src=""
        width={"100%"}
        style={{ mixBlendMode: "darken" }}
        height={"100%"}
      /> */}
    </Grid>
  );
};
export default RightSide;
