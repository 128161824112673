import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const NotLogin = () => {

  const handleRedirect = () => {
    window.location.href = 'http://localhost:3001/signup';  // Replace with the desired URL
  };


  return (
    <Box textAlign={"center"} py={1}>
      <Link to={"login"}>
        <Button sx={{ px: 6 }}>Sign In</Button>
      </Link>

      <Typography variant="body2" py={1}>
        New Customer?{" "}
        <Link to={"#"} onClick={handleRedirect}>
          <span>Start Here</span>
        </Link>{" "}
      </Typography>
    </Box>
  );
};

export default NotLogin;
