import * as React from "react";
import {  styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    color: "#000",
    textTransform: "capitalize",
    background: "none",
    padding: "0px",
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
type breadCrumbInterface = {
  mainCategory?: string | undefined | null;
  category?: string | undefined | null;
  subCategory?: string | undefined | null;
};

export default function Breadcrumb({
  mainCategory,
  category,
  subCategory,
}: breadCrumbInterface) {
  return (
    <div role="" onClick={handleClick}>
      <Breadcrumbs aria-label="product List BreadCrumd">
        <StyledBreadcrumb label={mainCategory} />
        <StyledBreadcrumb label={category} />
        <StyledBreadcrumb label={subCategory} />
      </Breadcrumbs>
    </div>
  );
}
