import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, FormGroup, Paper } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useStyles from "./styles";
import useCategoryStore from "../../store/getAllCategory";
import Binocular from "../../assets/icon/binoculars.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import useNavigateParams from "../../hooks/useNavigateParams";
import { styled, lighten, darken } from "@mui/system";
const top100Films = [
  { title: "Apple", year: 1994 },
  { title: "Orange", year: 1972 },
  { title: "Fresh Fruit", year: 1974 },
  { title: "Dry Fruit", year: 2008 },
  { title: "Machinery", year: 1957 },
  { title: "Chemical", year: 1993 },
];

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const SearchInput = () => {
  const [search, setSearch] = React.useState<string | null>();
  const [selectCategory, setSelectCategory] = React.useState("All");
  const { classes } = useStyles();
  const handleChange = (event: SelectChangeEvent) => {
    setSearch(event.target.value);
    setSelectCategory(event.target.value);
  };
  const navigate = useNavigateParams();

  const { Lists, getAllCategory } = useCategoryStore((state) => ({
    Lists: state.List,
    getAllCategory: state.getCategoryList,
  }));

  React.useEffect(() => {
    getAllCategory();
  }, []);

  const navigateHandler = () => {
    navigate("search", {
      i: search?.toLocaleLowerCase() ? search?.toLocaleLowerCase() : "a",
      q: selectCategory.toLocaleLowerCase(),
    });
  };

  const options = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const searchInputHandler = (value: string | null) => {
    setSearch(value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormGroup className={classes.root} sx={{ width: "100%" }}>
        <Paper className={classes.outLineInput}>
          <Box>
            <Select
              value={selectCategory}
              onChange={handleChange}
              autoWidth
              className={classes.categorySelect}
              inputProps={{ "aria-label": "Select Your Category" }}
              sx={{width:"auto" }}
            >
              <MenuItem value="All" sx={{fontSize:"11.5px",lineHeight:"1"}}>All</MenuItem>
              {Lists?.map((data, index) => (
                <MenuItem
                  value={data.name}
                  key={index}
                  sx={{ textTransform: "capitalize",fontSize:"11.5px",lineHeight:"1" }}
                  
                  onClick={() => setSearch(data.name.toLocaleLowerCase())}
                >
                  {data.name.toLocaleLowerCase()}
                </MenuItem>
              ))}
              {/* <option value={"All"}>All</option>
              {Lists?.map((data, index) => (
                <option
                  key={index}
                  style={{ textTransform: "capitalize" }}
                  value={data.name}
                  onClick={() => setSearch(data.name.toLocaleLowerCase())}
                >
                  {data.name.toLocaleLowerCase()}
                </option>
              ))} */}
            </Select>
          </Box>
          <Box width={"100%"} height={"100%"}>
            <Autocomplete
              freeSolo
              sx={{
                height: "100%",
                ".MuiAutocomplete-input": { padding: 0 },
                ".MuiInputBase-root": {
                  p: 0,
                  height: "100%",
                  fontSize: "15px",
                  py: 0.2,
                },
                ".MuiAutocomplete-endAdornment": { top: "auto" },
              }}
              // className={classes.outLineInputAutoCompleate}
              options={top100Films.map((option) => option.title)}
              onChange={(event, value) => {
                searchInputHandler(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigateHandler();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                  label=""
                />
              )}
              size={"medium"}
            />
          </Box>
          {/* <Link to={`/search?${search}`}> */}
          <Button
            className={classes.searchButton}
            onClick={navigateHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigateHandler();
              }
            }}
          >
            <img src={Binocular}></img>
          </Button>
          {/* </Link> */}
        </Paper>
      </FormGroup>
    </Box>
  );
};
export default SearchInput;
