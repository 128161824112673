import { Toolbar, Box } from "@mui/material";
import React from "react";
import Drawer from "@mui/material/Drawer";
import useDrawerStore from "../../../store/drawerHandler";
import DrawerElements from "../../@DrawerElements";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useUserDetailStore from "../../../store/Data/userDetail";
import useStyles from "./styles";
import useLoginStore from "../../../store/login";

const drawerWidth = 350;

const DrawerUI = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { token } = useLoginStore((state) => ({
    token: state.token,
  }));

  const { open, setOpen } = useDrawerStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));
  const drawerHandler = () => {
    setOpen(open);
  };

  const { data } = useUserDetailStore((state) => ({
    data: state.data,
  }));

  return (
    <Box sx={{ postion: "relative" }}>
      <Drawer
        color="#000"
        variant="temporary"
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            position: "relative",
            zIndex: 0,
          },
        }}
        aria-label="category List"
        open={open}
        onClose={drawerHandler}
      >
        <Toolbar sx={{ background: theme.palette.primary.main }} />
        <Toolbar
          sx={{
            minHeight: "35px",
            background: theme.palette.primary.dark,
          }}
        />
        <List
          sx={{
            width: "100%",
            bgcolor: "#00072d",
            p: 0,
            m: 0,
            "&:hover": {
              background: "#012326",
              border: "none",
              boxShadow: "none",
            },
          }}
        >
          <ListItem
            sx={{
              "&:hover": {
                background: "none",
                border: "none",
                boxShadow: "none",
              },
            }}
          >
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="" />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant="h6">Hello,</Typography>}
              secondary={
                <Typography component="span" variant="h5">
                  {!token
                    ? "Hello,sign in"
                    : !data
                    ? "Loading.."
                    : !data?.message?.name
                    ? "Not Found"
                    : data?.message?.name}
                </Typography>
              }
            />
          </ListItem>
        </List>
        <DrawerElements />
      </Drawer>
      {open && (
        <Box className={classes.clsbtn} onClick={() => setOpen(open)}>
          <CloseIcon sx={{ color: "#fff" }} fontSize={"large"} />
        </Box>
      )}
    </Box>
  );
};

export default DrawerUI;
