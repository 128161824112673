import ListItemText from "@mui/material/ListItemText";

type DrawerTitleProps = {
  name?: String;
};
const DrawerTitle = ({ name }: DrawerTitleProps) => {
  return (
    <ListItemText
      primary={`${name?.toLowerCase()}`}
      primaryTypographyProps={{
        fontSize: 14,
        fontWeight: "500",
        textTransform: "capitalize",
        color: "#333",
      }}
    />
  );
};

export default DrawerTitle;
