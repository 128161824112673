import CenterBox from "../../core/CenterBox";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Card from "./Card";

interface IProps{
  color?:string
}
const SocialMedia = () => {
  return (
    <CenterBox gap="16px">
      <Card link="/facebook">
        <FacebookOutlinedIcon sx={{color:"#fff"}} fontSize="large" />
      </Card>
      <Card link="/twitter">
        <TwitterIcon sx={{color:"#fff"}} fontSize="large" />
      </Card>
      <Card link="/linkedIn">
        <LinkedInIcon sx={{color:"#fff"}} fontSize="large" />
      </Card>
      <Card link="/instagram">
        <InstagramIcon sx={{color:"#fff"}} fontSize="large" />
      </Card>
    </CenterBox>
  );
};
export default SocialMedia;
