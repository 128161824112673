import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

interface IRow {
  callQuotation?: boolean;
}

const QuotationRow = [
  "Name",
  // "subCategory",
  // "middleCategory",
  "IMPA Code",
  "Quantity",
  "Price",
  "TotalPrice",
];
const CartRow = [
  "Name",
  // "subCategory",
  // "middleCategory",
  "IMPA Code",
  "Quantity",
  "",
];

const RowItemCart = ({ callQuotation }: IRow) => {
  return (
    <TableRow
      sx={{
        "& > *": { borderBottom: "none" },
        border: "none",
        background: "#eef1ff",
        height: "30px",
        width: "100%",
      }}
    >
      {callQuotation === true || callQuotation
        ? QuotationRow.map((data, index) => (
            <TableCell
              key={index}
              align={
                index === 0 ? "left" : data === "IMPA Code" ? "left" : "center"
              }
              sx={{ color: "#000", fontSize: "13px" }}
              variant="head"
              size="small"
              colSpan={data === "Name" ? 6 : data === "IMPA Code" ? 2 : 1}
            >
              {data}
            </TableCell>
          ))
        : CartRow.map((data, index) => (
            <TableCell
              key={index}
              align={
                index === 0 ? "left" : data === "IMPA Code" ? "left" : "center"
              }
              sx={{ color: "#000", fontSize: "13px" }}
              variant="head"
              size="small"
              colSpan={data === "Name" ? 6 : data === "IMPA Code" ? 2 : 1}
            >
              {data}
            </TableCell>
          ))}
    </TableRow>
  );
};

export default RowItemCart;
