import { Box, Typography, Checkbox } from "@mui/material";
import Page from "../components/Page";
import Breadcrumb from "../core/BreadCrumb";
import Container from "../core/Container";
import NormalCard from "../core/Card/NormalCard";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputField from "../core/InputField";
import DateField from "../core/DateField";
import { Link } from "react-router-dom";
import CenterBox from "../core/CenterBox";
import ButtonUI from "../core/Button";

const CTMApplyNow = () => {
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(-1);
  const [DPort, setDPort] = useState("");
  const [dPortValid, setDPortlValid] = useState({
    isValid: true,
    message: "",
  });

  return (
    <Page title="Request for Quotation" description="description">
      <Container color="#fff">
        <Box px={10} py={2}>
          <Breadcrumb
            mainCategory="Home"
            category="Cash to Master"
            subCategory="Apply Now"
          />
          <NormalCard>
            <Box height={"100%"}>
              <Box display={"flex"} gap={"5px"} pb={2}>
                <img src="/icons/span.flag.png"></img>
                <Typography fontWeight={"600"}>Select An Amount</Typography>
              </Box>
              <Box display={"flex"} gap={"10px"}>
                {[1, 2, 3, 4, 5].map((data, index) => {
                  return (
                    <Box
                      key={index}
                      onClick={() => setSelectedBoxIndex(index)}
                      sx={{
                        height: "80px",
                        width: "300px",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          selectedBoxIndex === index ? "#e0e0e0" : "#fff", // This changes the color when the box is selected
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // This changes the color on hover
                        },
                        marginBottom: "10px", // Spacing between boxes for better visibility
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography fontWeight={"700"}>USD 20,000</Typography>
                        <Typography fontWeight={"400"} fontSize={"13px"}>
                          Flat Fee USD 468
                        </Typography>
                      </div>
                    </Box>
                  );
                })}
              </Box>
              <Box
                display={"flex"}
                gap={"5px"}
                alignContent={"center"}
                height={"100%"}
                py={2}
              >
                <img src="/icons/span.flag.png"></img>
                <Typography fontWeight={"600"}>
                  Cash to Master Details
                </Typography>
              </Box>
              <Box px={3}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Select Location
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Anchorage"
                      control={<Radio />}
                      label="Anchorage"
                    />
                    <FormControlLabel
                      value="Berth"
                      control={<Radio />}
                      label="Berth"
                    />
                    <FormControlLabel
                      value="Undecided"
                      control={<Radio />}
                      label="Undecided"
                    />
                  </RadioGroup>
                </FormControl>

                <Box>
                  <Typography fontWeight={"600"} py={2}>
                    Vessel Details
                  </Typography>
                  <Box>
                    <Box
                      display={"flex"}
                      gap={"20px"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      py={0.5}
                    >
                      <div style={{ width: "100%" }}>
                        <InputField
                          value={DPort}
                          name="vName"
                          setValue={() => setDPort("")}
                          type="text"
                          label="Vessel Name"
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          disabled={false}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <DateField
                          name="ETA"
                          value={new Date()}
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          label="ETA"
                          disabled={false}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <DateField
                          name="ETD"
                          value={new Date()}
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          label="ETD"
                          disabled={false}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Typography fontWeight={"600"} py={2}>
                    Local Agent Details
                  </Typography>
                  <Box>
                    <Box
                      display={"flex"}
                      gap={"20px"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      py={0.5}
                    >
                      <div style={{ width: "100%" }}>
                        <InputField
                          value={DPort}
                          setValue={() => setDPort("")}
                          type="text"
                          label="Agency Name"
                          name="aName"
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          disabled={false}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <InputField
                          value={DPort}
                          setValue={() => setDPort("")}
                          type="text"
                          name="email"
                          label="Agency Email"
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          disabled={false}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <InputField
                          value={DPort}
                          setValue={() => setDPort("")}
                          name="contact"
                          type="text"
                          label="Contact No"
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          disabled={false}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <InputField
                          value={DPort}
                          setValue={() => setDPort("")}
                          type="text"
                          name="person"
                          label="Contact Person"
                          handleInputChange={() => {}}
                          error={
                            dPortValid.isValid === false
                              ? dPortValid.message
                              : undefined
                          }
                          disabled={false}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>

                <CenterBox height="100%">
                  <div>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={
                        <Typography component={"span"}>
                          I agree with WORLDQART{" "}
                          <Link to={"#"}> terms and conditions.</Link>
                        </Typography>
                      }
                    />
                  </div>
                </CenterBox>
                <CenterBox>
                  <div>
                    <Typography component={"span"} fontSize={"20px"}>
                      Total :{" "}
                      <Link to={"#"}>
                        {" "}
                        <strong> USD 20,468.00 </strong>
                      </Link>
                    </Typography>
                  </div>
                </CenterBox>
                <CenterBox>
                  <ButtonUI>Next</ButtonUI>
                </CenterBox>
              </Box>
            </Box>
          </NormalCard>
        </Box>
      </Container>
    </Page>
  );
};
export default CTMApplyNow;
