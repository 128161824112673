import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import country from "../../../mockData/footer/country.json";
import { Link } from "react-router-dom";
import useStyles from "../styles";
import { Box } from "@mui/material";
const Bottom = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container className="center" spacing={1} py={0}>
        <Grid item md={8}>
          <Grid container className="center">
            <Grid item md={12}>
              <Typography
                variant="h6"
                color="#fff"
                textAlign={"center"}
                fontWeight={700}
                pb={1}
              >
                {country.title}{" "}
              </Typography>
            </Grid>
            {country.List.map((data, index) => {
              return (
                <Grid item key={index} px={2}>
                  <MenuItem className={classes.menuItems}>
                    <Link to={data}>
                      <Typography variant="subtitle2" color={"#fff"}>
                        {data}
                      </Typography>
                    </Link>
                  </MenuItem>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <br />

      <div
        style={{
          display: "flex",
          textAlign: "center",
          color: "#fff",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <Link to={"#"}>
            <Typography color={"#fff"} fontSize={"14px"}>
              Conditions of use
            </Typography>
          </Link>
          <Link to={"#"}>
            <Typography color={"#fff"} fontSize={"14px"}>
              Privacy Policy
            </Typography>
          </Link>
        </div>
      </div>
      <br />

      <Typography textAlign={"center"} color={"#fff"} fontSize={"14px"}>
        2020-2023 Worldqart.com. All rights reserved.
      </Typography>
    </>
  );
};
export default Bottom;
