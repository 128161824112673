// null | HTMLElement

import create from "zustand";

interface Store {
  accElement: null | HTMLElement;
  accOpen: boolean;
  setAccElement: (accElement: HTMLElement | null) => void;
  setAccOpen: (accOpen: boolean) => void;
}

const useAccElementStore = create<Store>(
  (set): Store => ({
    accElement: null,
    accOpen: false,
    setAccElement(accElement) {
      set({ accElement: accElement });
    },
    setAccOpen(accOpen) {
      set({ accOpen: accOpen });
    },
  })
);
export default useAccElementStore;
