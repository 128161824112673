import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CenterBox from "../../../../core/CenterBox";
import Rate from "../../../../core/Rating";
import { Link } from "react-router-dom";

interface ICard {
  name?: string;
  id: string;
}

const SupplierCard = ({ name, id }: ICard) => {
  return (
    <Box width={"400px"}>
      <Paper
        sx={{
          width: "90%",
          p: 1,
          borderRadius: "16px",
          height: "100%",
          position: "relative",
        }}
        elevation={3}
      >
        <Box position={"absolute"} height={"50px"} right={0}>
          <Typography
            color={"sucess"}
            textAlign={"end"}
            fontWeight={"bold"}
            sx={{
              background: "#ff0000",
              color: "#fff",
              borderRadius: "0px 4px 0px 0px",
            }}
            px={0.5}
            fontSize={"10px"}
          >
            Our Choice
          </Typography>
        </Box>
        <Box
          display={"flex"}
          gap={"0px"}
          px={1}
          alignItems={"center"}
          height={"60px"}
        >
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Supplier 1" secondary="mumbai,MH,Indai" />
        </Box>
        <Divider />
        <CenterBox height={"40px"}>
          <Rate value={4.5} />
        </CenterBox>

        <Box height={"50px"} py={0} display={"flex"}>
          <Box
            width={"50%"}
            height={"100%"}
            borderRight={"1px solid #ccc"}
            className="center"
          >
            <Typography component={"span"} fontSize={"18px"} fontWeight={"700"}>
              145 / 160{" "}
              <Typography fontSize={"12px"} component={"span"}>
                item
              </Typography>
            </Typography>
          </Box>
          <Box width={"50%"} height={"100%"} className="center">
            <Typography component={"span"} fontSize={"18px"} fontWeight={"700"}>
              95%
            </Typography>
          </Box>
        </Box>
        <Box className="center">
          <Typography
            variant="h2"
            lineHeight={"35px"}
            color={"#000"}
            fontWeight={"700"}
            textAlign={"center"}
            py={1}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              width: "180px",
              borderRadius: "8px",
            }}
          >
            Total <sup style={{ fontSize: "10px" }}>$</sup>18850
            <sup style={{ fontSize: "10px" }}>.00</sup>
          </Typography>
        </Box>

        <Box display={"flex"} gap={"10px"}>
          <Link to={`/quotation/${id}`} style={{ width: "100%" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                fontSize: "13px",
                textTransform: "capitalize",
                background: "#fff",
              }}
            >
              View Detail
            </Button>
          </Link>
          <Button
            variant="contained"
            fullWidth
            sx={{ fontSize: "13px", textTransform: "capitalize" }}
          >
            Proceed to Buy
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
export default SupplierCard;
