import Page from "../../../components/Page";
import { Order } from "../../../sections/@Header/@userDetail";
const OrderHistory = () => {
  return (
    <Page title="Your Order History" description="order history">
      <Order/>
    </Page>
  );
};
export default OrderHistory;
