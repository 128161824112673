import { NextBtnSm, PreviousBtnSm } from "../Slick";

const SmallSlick = () => {
  const settings = {
    prevArrow: <PreviousBtnSm />,
    nextArrow: <NextBtnSm />,
    // className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px 100px",
    slidesToShow: 7.5,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return settings;
};
export default SmallSlick;
