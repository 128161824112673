import { Box, Grid, Typography } from "@mui/material";
import menu from "../../../mockData/subHeader/category-show/menu.json";
import productList from "../../../mockData/subHeader/category-show/productList.json";
import { CategoryShow } from "../../../sections/@SubHeader";
import CenterBox from "../../../core/CenterBox";
import "./category.css";
import MegaMenu from "./MegaMenu";
const Category = () => {
  console.log("menu list is", menu);
  return (
    <>
      <div className="container">
        <CenterBox>
          <Typography variant="h4" color={"#000"}>
            {menu.title}
          </Typography>
        </CenterBox>
        <nav>
          <ul>
            {menu.list.map((data, index) => {
              return <MegaMenu data={data} key={index} />;
            })}
          </ul>
        </nav>
      </div>
      <Grid container>
        <Grid item md={12} p={2}>
          <img
            src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Wireless/katariy/Events_23/JanART_2023/PC_Header.gif"
            style={{ borderRadius: "33px" }}
          ></img>
        </Grid>

        <Grid item md={12}>
          <Box p={2}>
            <Typography variant="h1">{productList.title}</Typography>
            <Typography variant="h2">{productList.subtitle}</Typography>
          </Box>
        </Grid>

        <Grid item md={1.5}></Grid>
        <Grid item md={10.5}>
          <CategoryShow />
        </Grid>
      </Grid>
    </>
  );
};
export default Category;
