import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const NextBtnSm = (props: any) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      <div
        className={className}
        onClick={onClick}
        style={{
          right: "20px",
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            marginTop: "90px",
            color: "rgb(246, 246, 246,50%)",
            fontSize: "40px",
            borderRadius: "10px",
            background: "none",
            height: "80px",
            border: "1px solid #ccc",
            transition: "1s",
            "&:hover": {
              border: "2.5px solid #448ccb",
              background: "rgb(246, 246, 246,50%)",
            },
            fill: "#000",
          }}
        />
      </div>
    </>
  );
};
export default NextBtnSm;
