import { Grid } from "@mui/material";
import { IMegaMenu } from "../../../models/IMegaMenu";
import "./category.css";

const MegaMenu = ({ data }: IMegaMenu) => {
  return (
    <li>
      <a>{data.title}</a>
      <div className="megamenulist">
        <Grid container className="center">
          <Grid item md={8}>
            <Grid container className="center">
              <Grid item md={7}>
                <Grid container className="center">
                  {data.list.map((data) => {
                    return (
                      <Grid item md={6}>
                        <h3 className="h3">{data.title}</h3>
                        <ul className="mm-category-list">
                          {data.list.map((data) => {
                            return (
                              <li>
                                <a>{data.name}</a>
                              </li>
                            );
                          })}
                        </ul>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid container spacing={3}>
                  {data.imagepath.map((data) => {
                    return (
                      <Grid item md={6}>
                        <img
                          src="https://images-eu.ssl-images-amazon.com/images/G/31/img18/Wireless/CEEX/OnePlus6/IN_WLME_OnePlus7Pro_Miscellaneous_472x674.jpg"
                          height={"100%"}
                          width={"100%"}
                        ></img>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </li>
  );
};
export default MegaMenu;
