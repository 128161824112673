import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  toolBar: {
    padding: "0px !important",
    height: "60px",
    position: "relative",
    background: "#000",
  },
  minHeight: {
    height: "60px",
  },

  menuItems: {
    "&.MuiMenuItem-root": {
      padding: "5px 0px",
      "&:hover": {
        h6: {
          color: "#fff",
        },
        background: "none",
      },
    },
  },
});
export default useStyles;
