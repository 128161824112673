import Page from "../../../components/Page";
import { Detail } from "../../../sections/@Header/@userDetail";

const YourAccont = () => {
  return (
    <Page
      title="Your Account details"
      description="here is the login page details"
    >
      <Detail />
    </Page>
  );
};
export default YourAccont;
