import { Typography } from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
interface titleInterface {
  title: string | number;
}
const Title = ({ title }: titleInterface) => {
  return (
    <>
      <Typography
        color={"#000"}
        fontSize={"14px"}
        lineHeight={"auto"}
        fontWeight={"500"}
        component={"span"}
        display={"flex"}
        alignItems={"center"}
        
      >
        {title} 
      </Typography>
    </>
  );
};
export default Title;
