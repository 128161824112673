import { Box, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CenterBox from "../../../core/CenterBox";
import menu from "../../../mockData/subHeader/best-seller/menu.json";
import productList from "../../../mockData/subHeader/best-seller/productList.json";
import { BestSellerList } from "../../../sections/@SubHeader";
const BestSeller = () => {
  return (
    <Grid container>
      {/* <Grid item md={12}>
        <Grid container p={2}>
          {menu.map((data) => {
            return (
              <Grid key={data.id}>
                <CenterBox height="100%">
                  <Link to={data.path}>
                    <CenterBox height="100%">
                      <Box px={1}>
                        <Typography variant="h6" color={"#000"}>{data.title}</Typography>
                      </Box>
                    </CenterBox>
                  </Link>
                </CenterBox>
              </Grid>
            );
          })}
        </Grid>
        <Divider />
      </Grid> */}

      <Grid item md={12}>
        <Box p={2}>
          <Typography variant="h1" fontWeight={"600"}>
            {productList.title}
          </Typography>
          <Typography variant="h2">{productList.subtitle}</Typography>
        </Box>
      </Grid>

      <Grid item md={12}>
        <BestSellerList />
      </Grid>
    </Grid>
  );
};
export default BestSeller;
