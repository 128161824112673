import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Rate from "../../core/Rating";
import list from "../../mockData/subHeader/category-show/productList.json";
import { Small } from "../../core/SlickSettings";
import ProductCard from "../../components/ProductCard";

const CategoryShow = () => {
  const settings = Small();

  return (
    <Box p={2}>
      <Box sx={{ background: "#fff", borderRadius: "5px" }}>
        <Box p={2}>
          <Typography variant="h4">{list.product.title}</Typography>
        </Box>
        <Grid container pb={2}>
          <Slider {...settings}>
            {list.product.list.map((data, index) => {
              return <ProductCard data={data} key={index} />;
            })}
          </Slider>
        </Grid>
      </Box>
    </Box>
  );
};
export default CategoryShow;
