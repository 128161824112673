import React from "react";
import Page from "../../../components/Page";
import Container from "../../../core/Container";
import { Box, Divider, Typography, Chip, Grid } from "@mui/material";
// import Breadcrumb from "../core/BreadCrumb";
import AddIcon from "@mui/icons-material/Add";
// import SpaceBetween from "../core/SpaceBetween";
import SpaceBetween from "../../../core/SpaceBetween";

function Profile() {
  return (
    <Page title="Caption" description="Here is your profile">
      <Container color="#fff">
        <Grid container  sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
          <Grid md={9}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                height={250}
                width={"100%"}
                mt={2}
                borderRadius={"8px"}
                display={"flex"}
                gap={"10px"}
              >
                <img
                  src="https://www.freepnglogos.com/uploads/ship-png/ship-contact-maci-14.png"
                  height={"100%"}
                />
                <Box width={"100%"} p={2}>
                  <Typography variant="h4" gutterBottom>
                    Deepak
                  </Typography>

                  <Box py={1}>
                    <Typography fontWeight={"bold"}>
                      {" "}
                      Other Details :
                    </Typography>
                    <Box sx={{ display: "flex" }} py={0}>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        lineHeight={"17px"}
                        px={2}
                      >
                        Role :
                        <Typography
                          variant="h5"
                          color={"#000"}
                          lineHeight={"17px"}
                        >
                          {"Caption"}
                        </Typography>
                      </Typography>
                      <Divider orientation="vertical" flexItem />

                      <Typography
                        variant="h6"
                        color={"#000"}
                        lineHeight={"17px"}
                        px={2}
                      >
                        Currect Port :
                        <Typography
                          variant="h5"
                          color={"#000"}
                          lineHeight={"17px"}
                        >
                          {"Mumbai MH"}
                        </Typography>
                      </Typography>
                      <Divider orientation="vertical" flexItem />

                      <Typography
                        variant="h6"
                        color={"#000"}
                        lineHeight={"17px"}
                        px={2}
                      >
                        Route :
                        <Typography
                          variant="h5"
                          color={"#000"}
                          lineHeight={"17px"}
                        >
                          {"USA To Indai"}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box py={1}>
                    <Typography fontWeight={"bold"}> Crew Details :</Typography>
                    <Box sx={{ display: "flex" }} py={0}>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        lineHeight={"17px"}
                        px={2}
                      >
                        Ship IMO :
                        <Typography
                          variant="h5"
                          color={"#000"}
                          lineHeight={"17px"}
                        >
                          {"0012-123-445"}
                        </Typography>
                      </Typography>
                      {/* <Divider orientation="vertical" flexItem /> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Profile;
