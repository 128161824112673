import {
  AppBar,
  Grid,
  Toolbar,
  Container,
  Box,
  Badge,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SpaceBetween from "../../../../core/SpaceBetween";
import useStyles from "./styles";
import Logo from "../../../../core/Logo";
import CenterBox from "../../../../core/CenterBox";
import { Link } from "react-router-dom";
import SearchInput from "../../../../core/SearchInput";
import Typography from "@mui/material/Typography";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

const TopNav = () => {
  const classes = useStyles();
  return (
    <nav>
      {/* first section of Nav  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "60px",
          background: "#000",
        }}
      >
        <Box mx={1}>
          <span>
            <MenuIcon sx={{ color: "#fff", fontSize: "1.8rem" }} />
          </span>
          <Logo />
        </Box>
        <Box>
          <Box sx={{ display: "flex", gap: "1.5rem" }}>
            <CenterBox>
              <Badge badgeContent={40} color="primary">
                <ShoppingCartIcon fontSize="large" sx={{ color: "#fff" }} />
              </Badge>
            </CenterBox>
            <CenterBox>
              <Avatar
                alt="profile pic"
                src="https://cdn.pixabay.com/photo/2022/06/17/12/57/medieval-7267875_960_720.jpg"
                sx={{ mr: 1 }}
              />
            </CenterBox>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "40px",
          background: "#000",
          width: "100%",
        }}
      >
        <Box mx={1} width="100%">
          <SearchInput />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "50px",
          background: "#000",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
            <span>
              <AddLocationAltIcon sx={{ color: "#fff", fontSize: "1.2rem" }} />
            </span>{" "}
            Select Port of Delivery
          </Typography>
        </Box>
      </Box>
    </nav>
  );
};
export default TopNav;
