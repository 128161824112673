import { Box, Grid, Button, Divider, IconButton, Tooltip } from "@mui/material";
import CenterBox from "../../core/CenterBox";
import Title from "./Helper/Title";
import React, { Fragment, useEffect, useState } from "react";
import QuentityEnter from "../../core/QuentityEnter";
import CircularLoader from "../../core/CircularLoader";
import { IProudctList } from "../../models/IProductList";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { CartService } from "../../services/CartServices";
import { IState } from "../../models/IState";
import Tosted from "../../core/Tosted";
import useCallDetailStore from "../../store/Data/callUDetail";
import { Delete } from "@mui/icons-material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

interface IProduct {
  data: IProudctList;
  modelHandler: () => void;
  id: number;
}

const ProductCart = ({ data, modelHandler, id }: IProduct) => {
  const [quantity, setQuantity] = React.useState(data.quantity);
  const { call, setCall } = useCallDetailStore((state) => ({
    call: state.call,
    setCall: state.setCall,
  }));

  const [state, setState] = useState<IState>({
    loader: false,
    message: "",
    severity: undefined,
    tosted: false,
  });

  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
        setCall(false);
      }, 5000);
    }
  };

  const cartHandler = async (id: string, quantity: number) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.addItemCart(id, quantity);
      setCall(true);
      setState({
        loader: false,
        tosted: true,
        message: "Product Add ",
        severity: "success",
      });
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const removeHandler = async (id: string) => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      await CartService.removeItem(id);
      setCall(true);
      setState({
        loader: false,
        tosted: true,
        message: "Remove An Product",
        severity: "success",
      });
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
    }
  };

  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <Fragment key={id}>
      <Grid
        container
        sx={{
          background: id % 2 === 0 ? "#EEF1FF" : "#fff",
          height: "75px",
          "&:hover": {
            background: "#f6f6f6",
          },
        }}
      >
        <Grid
          item
          sm={1.5}
          sx={{ position: "relative", cursor: "pointer" }}
          borderLeft={"1px solid #ccc"}
          borderRight={"1px solid #ccc"}
          height={"100%"}
          onClick={modelHandler}
        >
          <Box
            position={"absolute"}
            sx={{ height: "20px", width: "25px", right: "5px", zIndex: "100" }}
          >
            <IconButton>
              <FavoriteBorderIcon sx={{ color: "#ff6600" }} />
            </IconButton>
          </Box>
          <CenterBox height="100%">
            <Box p={2} height={"100%"} position={"relative"}>
              <img
                src={data?.productPicture ? data?.productPicture[0].img : ""}
                style={{
                  objectFit: "cover",
                  mixBlendMode: "multiply",
                  height: "100%",
                  width: "100%",
                }}
              />
            </Box>
          </CenterBox>
        </Grid>
        <Grid
          item
          sm={1.2}
          borderRight={"1px solid #ccc"}
          sx={{
            height: "100%",
            cursor: "pointer",
          }}
          className="center"
        >
          {data.impaCode}
        </Grid>
        <Grid
          item
          sm={4.3}
          onClick={modelHandler}
          borderRight={"1px solid #ccc"}
          sx={{ cursor: "pointer" }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              px: 2,
              position: "relative",
              "&:hover > .iconButton": {
                opacity: 1,
                visibility: "visible",
              },
            }}
          >
            <Box
              position={"absolute"}
              sx={{
                right: "0px",
                bottom: 0,
                opacity: 0,
                visibility: "hidden",
                transition: "opacity 0.3s ease-in-out", // 0.3s is the duration of the animation
              }}
              className="iconButton"
            >
              <Tooltip title="Add Note">
                <IconButton size="large" color="success">
                  <EditNoteIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Box>
            <Title title={data.name} />
          </Box>
        </Grid>
        <Grid
          item
          sm={1}
          borderRight={"1px solid #ccc"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ cursor: "pointer" }}
        >
          <Title title={data.uom} />
        </Grid>

        <Grid item sm={2} borderRight={"1px solid #ccc"}>
          <CenterBox px={"10px"} height="100%">
            <QuentityEnter
              quantity={quantity}
              increaseClickHandler={increaseClickHandler}
              decreaseClickHandler={decreaseClickHandler}
              InputHandler={InputHandler}
            ></QuentityEnter>
          </CenterBox>
        </Grid>

        <Grid item sm={2} borderRight={"1px solid #ccc"}>
          {/* <CenterBox height="100%" px={"5px"}>
            <Box width={"70%"}>
              {quantity !== 0 && quantity === data.quantity ? (
                <Button
                  variant="outlined"
                  sx={{ fontSize: "10px", height: "35px", width: "100%" }}
                  onClick={() => removeHandler(data._id)}
                >
                  {!loader ? "Remove" : <CircularLoader />}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={quantity > 0 ? false : true}
                  onClick={() => cartHandler(data._id, quantity)}
                  sx={{ fontSize: "10px", height: "35px", width: "100%" }}
                  fullWidth
                  size="small"
                >
                  {!loader ? "Add To Cart" : <CircularLoader />}
                </Button>
              )}
            </Box>
          </CenterBox> */}

          <Box
            display={"flex"}
            px={"5px"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {data.quantity > 0 ? (
              <Box sx={{ display: "flex", gap: "3px", width: "100%" }}>
                <Button
                  variant="contained"
                  disabled={
                    data.quantity !== quantity || quantity === 0 ? false : true
                  }
                  onClick={() => cartHandler(data._id, quantity)}
                  sx={{
                    fontSize: "14px",
                    height: "40px",
                    width: "100%",
                    borderRadius: "36px",
                    boxShadow: "none",
                    background: "#003366",
                    color: "#fff",
                    "&:disabled": {
                      background: "none",
                      color: "#003366",
                      boxShadow: "none",
                      textShadow: "none",
                      border: "1px solid #003366",
                      ":before": {
                        boxShadow: "none",
                      },
                    },
                  }}
                  fullWidth
                  size="small"
                >
                  {!loader ? "Update" : <CircularLoader />}
                </Button>
                <IconButton sx={{ width: "40px" }}>
                  <Delete />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="contained"
                disabled={quantity > 0 ? false : true}
                onClick={() => cartHandler(data._id, quantity)}
                sx={{
                  fontSize: "14px",
                  height: "40px",
                  width: "100%",
                  borderRadius: "36px",
                  boxShadow: "none",
                  background: "#F60",
                  color: "#fff",

                  "&:disabled": {
                    background: "none",
                    color: "#ff6600",
                    boxShadow: "none",
                    textShadow: "none",
                    border: "1px solid #ff6600",
                    ":before": {
                      boxShadow: "none",
                    },
                  },
                }}
                fullWidth
                size="small"
              >
                {!loader ? "Add To Cart" : <CircularLoader />}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </Fragment>
  );
};

export default ProductCart;
