import create from "zustand";
import { IUserDetail } from "../../models/IUserDetail";

interface Store {
  data: IUserDetail | undefined;
  setData: (data: IUserDetail) => void;
}

const useUserDetailStore = create<Store>(
  (set): Store => ({
    data: undefined,
    setData(data) {
      set({ data: data });
    },
  })
);
export default useUserDetailStore;
