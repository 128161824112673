import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IItemHeading {
  open: {
    open: boolean;
    index: number;
  };
  setOpen: (open: boolean, index: number) => void;
  name: string;
  totalItem: number;
  index: number;
}

const HeadingItem = ({
  open,
  setOpen,
  name,
  totalItem,
  index,
}: IItemHeading) => {
  const cellStyle = {
    borderBottom: "1px solid #ccc",
    fontSize: "14px",
    padding: "0px",
    pointer: "cursor",
  };
  return (
    <TableRow
      sx={{
        border: "none",
        background: open.open && open.index === index ? "#f7f7f7" : "none",
        cursor: "pointer",
        "&.MuiTableCell-root": { border: "1px solid #000" },
      }}
      onClick={() => setOpen(!open.open, index)}
    >
      <TableCell sx={cellStyle} align={"left"} colSpan={4}>
        <ListItem component="div" disablePadding sx={{ p: 0.8 }}>
          <ListItemText
            primary={`${name?.toLowerCase()}`}
            primaryTypographyProps={{
              fontSize: 14,
              fontWeight: "500",
              textTransform: "capitalize",
              color: "#333",
            }}
          />
          <ListItemText
            primary={totalItem}
            primaryTypographyProps={{
              fontSize: 14,
              fontWeight: "500",
              textTransform: "capitalize",
              textAlign: "end",
              color: "#333",
            }}
          />

          <ListItemIcon sx={{ justifyContent: "right", right: 0 }}>
            {open.open && open.index === index ? (
              <KeyboardArrowUpIcon sx={{ fontWeight: "700" }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ fontWeight: "700" }}
              />
            )}
          </ListItemIcon>
        </ListItem>
      </TableCell>
    </TableRow>
  );
};
export default HeadingItem;
