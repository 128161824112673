import { Divider, Grid } from "@mui/material";
import { useState } from "react";
import Page from "../../../components/Page";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SpaceBetween from "../../../core/SpaceBetween";
import { Link } from "react-router-dom";
import { Box, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";

import list from "../../../mockData/header/userDetails/order.json";

const steps = [
  "Order Place",
  "Order Packed at 12/02/2023 ",
  "Order is Arrived on Your Location ",
  "Order Delived",
];

const TrackOrder = () => {
  const [activeStep, setActiveStep] = React.useState(4);

  const style = {
    height: 250,
    bgcolor: "#fff",
    border: "1px #D5D9D9 solid",
    borderRadius: "8px",
    zIndex: "99999",
  };
  const title = {
    borderBottom: "1px solid #D5D9D9",
    backgroundColor: "#F0F2F2",
    borderRadius: "8px 8px 0 0",
    padding: 2,
    color: "#fff",
  };
  const subtitle = {
    padding: 3,
  };

  const heading = {
    fontSize: "12px",
    lineHeight: "16px",
  };

  return (
    <Page title="Track Order" description="Track Order Now for More">
      <Box>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <Grid item md={8}>
              <Box my={1}>
                <Box py={2}>
                  <Typography variant="h1">{"Your Track Order"}</Typography>
                  <Typography variant="h2">
                    {"Your Track Order now for that"}
                  </Typography>
                </Box>
                {/* this is tracking OrderHistory  */}
                <Paper
                  component={Box}
                  p={2.5}
                  elevation={3}
                  sx={{ display: "flex", gap: "3", borderRadius: "8px" }}
                >
                  <Box
                    sx={{ width: "30%", borderRight: "1px solid #ccc" }}
                    p={1}
                  >
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};

                        labelProps.optional = (
                          <Typography variant="subtitle2">
                            Lorman provides an overview
                          </Typography>
                        );

                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <Typography variant="subtitle1" color={"#000"}>
                                {label}
                              </Typography>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>
                  <Box sx={{ width: "70%" }}>
                    <SpaceBetween>
                      <Box p={2}>
                        <Box>
                          <Typography variant="h4" gutterBottom>
                            Order Details
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ display: "flex" }}
                            gutterBottom
                          >
                            {"Total Quantity "}
                            <Typography
                              variant="subtitle1"
                              pl={0.5}
                              color={"#000"}
                            >
                              850 Items
                            </Typography>
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ display: "flex" }}
                            gutterBottom
                          >
                            {"Total Cost "}
                            <Typography
                              variant="subtitle1"
                              pl={0.5}
                              color={"#000"}
                            >
                              $850000
                            </Typography>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ display: "flex" }}
                            gutterBottom
                          >
                            {"You Save  "}
                            <Typography
                              variant="subtitle1"
                              pl={0.5}
                              color={"#000"}
                            >
                              $5000
                            </Typography>
                          </Typography>
                        </Box>
                        <Box sx={{ position: "absolute", bottom: 30 }}>
                          <Link to={"/your-account/order-history"}>
                            Click Know More
                          </Link>
                        </Box>
                      </Box>
                      {/* right side Data  */}
                      <Box px={2}>
                        <Box pt={3}>
                          <Typography variant="subtitle2">
                            Order #12121212
                            <br />
                            ships to Deepak Mishra
                            <br />
                            Ship Id 121554
                          </Typography>
                          <br />
                          <Divider />
                          <br />
                          <Typography variant="subtitle1" color={"#000"}>
                            Your order has
                            <br />
                            Delived at Today at 5pm
                            <br />
                            For Safe Delived
                          </Typography>
                        </Box>
                      </Box>
                    </SpaceBetween>
                  </Box>
                </Paper>
                {/* previous order  */}

                <Box py={0}>
                  <Box p={2}>
                    {list.orderList.map((data) => {
                      return (
                        <Box py={2}>
                          <Box sx={style}>
                            <Box sx={title}>
                              <SpaceBetween>
                                <Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Box px={2}>
                                      <div style={heading}>
                                        <span
                                          style={{
                                            color: "#565959",
                                            fontSize: "12px",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          Order placed
                                        </span>
                                      </div>
                                      <div style={heading}>
                                        <span
                                          style={{
                                            color: "#565959",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          {data.date}
                                        </span>
                                      </div>
                                    </Box>
                                    <Box px={2}>
                                      <div style={heading}>
                                        <span
                                          style={{
                                            color: "#565959",
                                            fontSize: "12px",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          Total
                                        </span>
                                      </div>
                                      <div style={heading}>
                                        <span
                                          style={{
                                            color: "#565959",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          ${data.Cost}.00
                                        </span>
                                      </div>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box px={2}>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "12px",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        Order # {data.orderId}
                                      </span>
                                    </div>
                                    <div style={heading}>
                                      <span
                                        style={{
                                          color: "#565959",
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        {data.date}
                                      </span>
                                    </div>
                                  </Box>
                                </Box>
                              </SpaceBetween>
                            </Box>
                            <Box p={2}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                    color: "#0F1111",
                                  }}
                                >
                                  SucessFully
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#0F1111",
                                  }}
                                >
                                  Paid On: {data.date}{" "}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};
export default TrackOrder;
