import create from "zustand";

interface Store {
    totalCartItems: number;
    setTotalCartItems: (quentity: number) => void;
}

const useTotalCartItemStore = create<Store>(
    (set): Store => ({
        totalCartItems: 0,
        setTotalCartItems(quentity) {
            set({ totalCartItems: quentity });
        },
    })
);
export default useTotalCartItemStore;
